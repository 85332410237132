import { IResponseData } from 'types/api';

import { FetchHelper } from 'helpers/FetchHelper';

export type RestRepository = {
  index<P, R>(params?: P): Promise<IResponseData<R>>;
  show<P, R>(id: number, params?: P): Promise<IResponseData<R>>;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  create<P>(payload?: P): Promise<IResponseData<any>>;
  update<P, R>(id: number, params?: P): Promise<IResponseData<R>>;
  delete(id: number): Promise<null>;
};

export const createRepository = (rootPath: string): RestRepository => ({
  async index<P, R>(params?: P): Promise<IResponseData<R>> {
    return FetchHelper.get<IResponseData<R>>(rootPath, params);
  },

  async show<P, R>(id: number, params?: P): Promise<IResponseData<R>> {
    return FetchHelper.get<IResponseData<R>>(id ? `${rootPath}${id}/` : rootPath, params);
  },

  async create<P, R>(payload?: P): Promise<IResponseData<R>> {
    return FetchHelper.post<P, IResponseData<R>>(rootPath, payload);
  },

  async update<P, R>(id: number, payload: P): Promise<IResponseData<R>> {
    return FetchHelper.patch<P, IResponseData<R>>(`${rootPath}${id}/`, payload);
  },

  async delete(id: number): Promise<null> {
    return FetchHelper.delete(`${rootPath}${id}/`);
  },
});
