import { ResourceSlice, SliceOptions } from 'types/slices';

import { RequestState } from 'enums/requestState';

const deleteResourceSlice = (options: SliceOptions): ResourceSlice => {
  const { resource, repository } = options;

  return {
    initialState: {
      [resource]: {
        deleteStatus: RequestState.isIdle,
        errors: null,
      },
    },
    reducers: {
      deleteResourceStart(state) {
        state[resource].deleteStatus = RequestState.isPending;
        state[resource].errors = null;
      },
      deleteResourceSuccess(state) {
        state[resource].deleteStatus = RequestState.isSuccess;
      },
      deleteResourceFail(state, { payload: { errors } }) {
        state[resource].deleteStatus = RequestState.isFailure;
        state[resource].errors = errors;
      },
    },
    actionCreators(restDispatch) {
      return {
        deleteResource: async (id) => {
          try {
            restDispatch('deleteResourceStart');
            const data = await repository.delete(id);
            restDispatch('deleteResourceSuccess', data);
          } catch (errors) {
            restDispatch('deleteResourceFail', { errors });
          }
        },
      };
    },
    abstractSelector: (state) => ({
      resource: state[resource],
    }),
  };
};

export default deleteResourceSlice;
