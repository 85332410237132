const FormHelperTextOverrides: OverrideWithTheme = (theme) => ({
  MuiFormHelperText: {
    styleOverrides: {
      contained: {
        position: 'absolute',
        top: '100%',
        right: 0,
        marginLeft: 0,
        marginRight: 0,
        fontSize: theme.typography.pxToRem(12),
        textAlign: 'right',
      },
    },
  },
});

export default FormHelperTextOverrides;
