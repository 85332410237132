import { refreshTokens } from 'repositories/tokens';

import { OpportunityTaskStatus } from 'enums/resources/opportunity';
import { SessionStorageKeys } from 'enums/sessionStorageKeys';
import { TaskPath } from 'enums/taskPath';

import { CookieHelper } from 'helpers/CookieHelper';
import { SessionStorage } from 'helpers/SessionStorage';
import { UrlHelper } from 'helpers/UrlHelper';

import { axiosInstance } from 'utils/axiosBaseQuery';
import { downloadFile, getFileName } from 'utils/fileUtils';
import { camelize, decamelize } from 'utils/keysConverter';
import { isInternalServerError, isUnauthorizedError, isUnprocessedEntityError } from 'utils/responseErrors';

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ITokenResponse } from 'domain/token/types';
import { NextRouter } from 'next/router';
import qs from 'qs';
import { lensPath, path, set } from 'ramda';
import { apiRoutes, appRoutes } from 'routes';
import { v4 as uuidv4 } from 'uuid';

type AmazonS3Data = {
  content: Blob;
  name: string;
};

export const axiosInstanceExternal = axios.create({ baseURL: '/api/v1' });

export const setAccessTokenToAxiosInstance = (accessToken: string): void => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};
  
export const setTabIdToAxiosInstance = (): void => {
  if (typeof window !== 'undefined') {
    const initTabId = (): string => {
      const id = SessionStorage.getTabIdFromSessionStorage();
      if (id) {
        SessionStorage.removeTabIdFromSessionStorage();
        return id;
      }
      return uuidv4();
    }
    const tabId = initTabId();
    axiosInstance.defaults.headers.common['X-Tab-ID'] = tabId;
    window.onbeforeunload = () => {
      SessionStorage.setTabIdInSessionStorage(tabId);
    }
  }
};

setAccessTokenToAxiosInstance(CookieHelper.getAccessToken());
setTabIdToAxiosInstance();

axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

const EXCLUDED_URLS = [
  apiRoutes.tasksSecuritiesCostsPath(),
  apiRoutes.tasksAmortizationSchedulesPath(),
  TaskPath.sharepointFolder,
  TaskPath.sharepointFile,
  TaskPath.draftClosingLetterEmail,
  TaskPath.engagementLetterEmail,
  TaskPath.engagementInEmail,
  TaskPath.finalClosingLetterEmail,
  TaskPath.quoteLetterEmail,
  TaskPath.workingPartyListEmail,
  TaskPath.authorizationFormEmail,
  TaskPath.defeasanceClosingSummaryEmail,
];

const refreshAccessTokenInterceptor = (router: NextRouter) => async (originalError) => {
  const isNoRequestOrZeroStatus =
    originalError?.response?.status === 0 || (!originalError.response && originalError.request);
  if (isNoRequestOrZeroStatus) {
    SessionStorage.setIsLostConnectionInLocalStorage();
    return Promise.reject();
  }
  SessionStorage.removeIsLostConnectionFromLocalStorage();

  const previousRequestConfig = originalError.config;
  const { url } = previousRequestConfig;

  if (isUnauthorizedError(originalError) && !previousRequestConfig.shouldRetryFailedRequest) {
    previousRequestConfig.shouldRetryFailedRequest = true;

    if (url === apiRoutes.tokenPath()) return Promise.reject(originalError);

    if (url === apiRoutes.tokenRefreshPath()) {
      CookieHelper.destroyAccessToken();

      router.push(appRoutes.loginPath());
      return Promise.reject(originalError);
    }

    const response: ITokenResponse = await refreshTokens();

    CookieHelper.setAccessToken(response.data.access);
    setAccessTokenToAxiosInstance(response.data.access);

    return axiosInstance({
      ...previousRequestConfig,
      headers: { ...previousRequestConfig.headers, Authorization: `Bearer ${response.data.access}` },
    }).catch((error) => {
      if (isUnauthorizedError(error)) {
        router.push(appRoutes.loginPath());
      }
    });
  }

  if (isUnprocessedEntityError(originalError) || originalError.response?.data?.status === OpportunityTaskStatus.failure) {
    SessionStorage.set(SessionStorageKeys.unprocessedEntityError, JSON.stringify(originalError.response.data.errors));
    return Promise.reject(originalError);
  }
  SessionStorage.remove(SessionStorageKeys.unprocessedEntityError);

  if (isInternalServerError(originalError)) {
    if (!EXCLUDED_URLS.some((excludedUrl) => originalError.response.config.url.indexOf(excludedUrl) !== -1)) {
      router.push(appRoutes.pageServerErrorPath());
    }
  }

  return Promise.reject(originalError);
};

export const initializeAxiosInterceptors = (router: NextRouter): void => {
  axiosInstance.interceptors.response.use((response) => {
    if (SessionStorage.isLostConnectionInLocalStorage()) {
      SessionStorage.removeIsLostConnectionFromLocalStorage();
    }
    return response;
  }, refreshAccessTokenInterceptor(router));
};

const handleResponse = ({ data }: AxiosResponse) => camelize(data);

const handleError = (axiosError: AxiosError) => {
  const errorsPath = ['response', 'data', 'errors'];
  const axiosErrorResponseWithCamelizedErrors = set(
    lensPath(errorsPath),
    camelize(path(errorsPath, axiosError)),
    axiosError,
  );

  return Promise.reject(axiosErrorResponseWithCamelizedErrors);
};

export const getFileData = async (url: string): Promise<AmazonS3Data> => {
  const { data, headers, config } = await axiosInstanceExternal.get(url, { responseType: 'blob' });
  const contentDisposition = headers?.['content-disposition'];
  const name = getFileName(config.url, contentDisposition);
  const type = headers['content-type'];
  const content = new Blob([data], { type });
  return {
    content,
    name,
  };
};

export const FetchHelper = {
  get: <R>(url: string, params?: AxiosRequestConfig, arrayFormat = 'repeat'): Promise<R> =>
    axiosInstance
      .get(url, {
        params: decamelize(params),
        paramsSerializer: (parameters) => qs.stringify(parameters.params || parameters, { encode: false, arrayFormat }),
      })
      .then(handleResponse)
      .catch(handleError),
  post: <D, R>(url: string, data?: D, params?: AxiosRequestConfig): Promise<R> =>
    axiosInstance.post(url, decamelize(data), params).then(handleResponse).catch(handleError),
  postWithoutAuth: <D, R>(url: string, data?: D): Promise<R> => {
    axiosInstance.defaults.headers.common.Authorization = null;
    return axiosInstance.post(url, decamelize(data)).then(handleResponse).catch(handleError);
  },
  postWithHeaders: <D, R>(url: string, data: D): Promise<R> =>
    axiosInstance.post(url, decamelize(data), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(handleResponse).catch(handleError),
  put: <D, R>(url: string, data: D): Promise<R> =>
    axiosInstance.put(url, decamelize(data)).then(handleResponse).catch(handleError),
  patch: <D, R>(url: string, data: D): Promise<R> =>
    axiosInstance.patch(url, decamelize(data)).then(handleResponse).catch(handleError),
  delete: (url: string): Promise<null> => axiosInstance.delete(url).then(handleResponse),
  download: (url: string, params?: QueryParams): void => {
    downloadFile(`${url}?${UrlHelper.stringifyParams(params)}`);
  },
};

export default FetchHelper;
