import { currentUserApi, useGetCurrentUserQuery, useLazyGetCurrentUserQuery } from 'domain/user/apiSlice';
import { ICurrentUser } from 'domain/user/types';
import { useDispatch } from 'react-redux';

type UseUser = {
  getCurrentUser: () => void;
  currentUser: ICurrentUser;
  resetCurrentUser: () => void;
  userError: unknown;
  isCurrentUserLoading: boolean;
};

const useUser = (): UseUser => {
  const dispatch = useDispatch();

  const { data: currentUser, isLoading: isCurrentUserLoading, error: userError } = useGetCurrentUserQuery();
  const [ getCurrentUser ] = useLazyGetCurrentUserQuery();

  const resetCurrentUser = () => dispatch(currentUserApi.util.resetApiState());

  return {
    getCurrentUser,
    currentUser,
    resetCurrentUser,
    userError,
    isCurrentUserLoading,
  };
};

export default useUser;
