import { DateHelpers } from 'helpers/DateHelpers';

import {
  getAutocompleteFieldInitialValue,
  getBooleanFieldInitialValue,
  getDateFieldInitialValue,
  getEditablePercentFieldInitialValue,
  getNumberFieldInitialValue,
  getPercentFieldInitialValue,
  getSelectFieldInitialValue,
  getTextFieldInitialValue,
  getYieldFieldInitialValue,
} from 'utils/formik';
import { isBlank } from 'utils/predicates';
import { Override } from 'utils/types';
import { dateValidationRule } from 'utils/yup';

import { IAccountIndex } from 'domain/account/types';
import { IManagerIndex } from 'domain/manager/types';
import { IOpportunityAccount } from 'domain/opportunity/account/types';
import { ICredIQFinancial } from 'domain/opportunity/credIQFinancial/types';
import { IOpportunityFormDealDMData } from 'domain/opportunity/dealManagement/types';
import { OpportunityFormLoanData } from 'domain/opportunity/loan/types';
import { IOpportunityLoanLookupIndexWithId } from 'domain/opportunity/loanLookup/types';
import { IOpportunityMiscellaneousFee } from 'domain/opportunity/miscellaneousFee/types';
import { IOpportunityOpbDate } from 'domain/opportunity/opbDate/types';
import { OpportunityPricingDetail } from 'domain/opportunity/pricingDetail/types';
import { IOpportunityProperty } from 'domain/opportunity/property/types';
import { IOpportunityTransactionCost } from 'domain/opportunity/transactionCost/types';
import { IOpportunityTransactionCostDM } from 'domain/opportunity/transactionCostDM/types';
import { IOpportunityShow } from 'domain/opportunity/types';
import { IOptions } from 'domain/options/types';
import { getPricingDetailInitialValues } from 'domain/pricingDetail/schema';
import { IPricingDetail } from 'domain/pricingDetail/types';
import { loanTermValidationRule, paymentDayValidationRule } from 'forms/opportunity/validationRules';
import { isNil, last, isEmpty } from 'ramda';
import * as yup from 'yup';

export type OpportunityFormData = {
  id: number;
  name: string;
  loan: OpportunityFormLoanData;
  dealManagement: IOpportunityFormDealDMData;
  property: IOpportunityProperty;
  transactionCost: IOpportunityTransactionCost;
  totalTransactionCost: IOpportunityTransactionCostDM;
  account: IAccountIndex | IOpportunityAccount;
  originator: IManagerIndex;
  dealManager: IManagerIndex;
  salesDevelopmentRepresentative: IManagerIndex;
  owner: IManagerIndex;
  miscellaneousFees: Array<IOpportunityMiscellaneousFee>;
  estimatedCloseDate: Date | null;
  newPricingDetail: OpportunityPricingDetail;
  probability: number | string | null;
  stage: string;
  additionalComments: string;
  analystComments: string;
  managerComments: string;
  address1: string;
  address2: string;
  addressCity: string;
  addressState: string;
  addressZip: string;
  deal: string;
  comments: string;
  calculatedAt: string;
  calculatedBy: string;
  areOpbDatesEmpty: boolean;
  opbDates: Array<IOpportunityOpbDate>;
  encodedSharepointUrl: string;
  pulledFromMoodysDate: string;
  isRepeatClient: boolean;
  isMuni: boolean;
  isCompeting: boolean;
  isMultipack: boolean;
  isCanadian: boolean;
  moodysFinancial: ICredIQFinancial;
} | null;

export type OpportunityFormSerializedData = Override<
  OpportunityFormData,
  {
    name: string;
    accountId: number;
    originatorId: number | null;
    dealManagerId: number | null;
    ownerId: number | null;
    salesDevelopmentRepresentativeId: number | null;
    estimatedCloseDate: string | null;
    isRepeatClient: boolean;
    isMuni: boolean;
    isCompeting: boolean;
    isCanadian: boolean;
    isMultipack: boolean;
    additionalComments: string | null;
    analystComments: string | null;
    managerComments: string | null;
    deal: string | null;
    stage: string | null;
    address1: string | null;
    address2: string | null;
    addressCity: string | null;
    addressState: string | null;
    addressZip: string | null;
    comments: string | null;
    miscellaneousFees: Array<IOpportunityMiscellaneousFee>;
    loan: Override<
      OpportunityFormLoanData,
      {
        firstPiPaymentDate: string;
        maturityDate: string;
        permittedDate: string;
        noteDated: string;
        servicerId: number;
        subServicerId: number;
        poolId: number;
        multipackId: number;
        successorBorrowerCompanyId: number;
      }
    >;
    dealManagement: Override<
      IOpportunityFormDealDMData,
      {
        counselFirmServicerId: number;
        externalSuccessorBorrower: string;
        lastEngagementLetterSendDate: string;
        receivedDate: string;
        lastDraftClosingStatementDate: string;
        circleDate: string;
        actualCloseDate: string;
        dhcExternalSuccessorBorrowerId: number;
        noticeSentDate: string;
        depositSentDate: string;
        wplSentDate: string;
        kickoffCallDate: string;
        closingStatementSentDate: string;
        servicersLetterDate: string;
        accountantReportDate: string;
        securitiesAuthDate: string;
        successorBorrowerEmployerIdentificationNumber: string;
      }
    >;
    transactionCost: Override<
      IOpportunityTransactionCost,
      {
        securitiesUsed: string | null;
      }
    >;
    totalTransactionCost: IOpportunityTransactionCostDM;
    property: Override<
      IOpportunityProperty,
      {
        name: string | null;
        ownershipEntity?: string | null;
        subtype?: string | null;
        generalLedgerAccountNumber?: string | null;
        address: string | null;
        address2?: string | null;
        city?: string | null;
        zipCode?: string | null;
        state?: string | null;
        assessorParcelNumber?: string | null;
        website?: string | null;
        type?: string | null;
      }
    >;
  }
>;

const getOpbDatesEmpty = (opportunity) =>
  opportunity.opbDates?.length ? opportunity.opbDates.every((item) => isNil(item.date)) : true;

const getLoanInitialValues = (opportunity: IOpportunityShow): OpportunityFormLoanData => {
  const { loan = null } = opportunity;
  if (isBlank(loan)) {
    return null;
  }

  return {
    ...opportunity.loan,
    originalLoanBalance: getTextFieldInitialValue(loan.originalLoanBalance),
    monthlyDebtService: getTextFieldInitialValue(loan.monthlyDebtService),
    firstPiPaymentDate: getDateFieldInitialValue(loan.firstPiPaymentDate),
    maturityDate: (loan.noteDated && loan.loanTerm) ? new Date(DateHelpers.addMonths(loan.noteDated, Number(loan.loanTerm), false)) : null,
    interestCalcMethod: getTextFieldInitialValue(loan.interestCalcMethod),
    interestOnlyPeriods: getTextFieldInitialValue(loan.interestOnlyPeriods),
    loanTerm: getTextFieldInitialValue(loan.loanTerm),
    permittedDate: getDateFieldInitialValue(loan.permittedDate),
    loanNumber: getTextFieldInitialValue(loan.loanNumber),
    coupon: getNumberFieldInitialValue(loan.coupon),
    subLoanNumber: getTextFieldInitialValue(loan.subLoanNumber),
    loanAmortization: getNumberFieldInitialValue(loan.loanAmortization),
    noteDated: getDateFieldInitialValue(loan.noteDated),
    isRepeatClient: getBooleanFieldInitialValue(loan.isRepeatClient),
    isMuni: getBooleanFieldInitialValue(loan.isMuni),
    isCompeting: getBooleanFieldInitialValue(loan.isCompeting),
    isCanadian: getBooleanFieldInitialValue(loan.isCanadian),
    isMultipack: getBooleanFieldInitialValue(loan.isMultipack),
    isSBNameDHC: loan.successorBorrowerCompany?.name === 'DHC',
    principleBalance: getYieldFieldInitialValue(loan.principleBalance),
    penalty: getYieldFieldInitialValue(loan.penalty),
    yieldMaintenancePercent: getPercentFieldInitialValue(loan.yieldMaintenancePercent),
    totalEstimatedPayoff: getYieldFieldInitialValue(loan.totalEstimatedPayoff),
    estimatedStubInterest: getYieldFieldInitialValue(loan.estimatedStubInterest),
    canadianInterestRate: getTextFieldInitialValue(loan.canadianInterestRate),
    paymentDay: getNumberFieldInitialValue(loan.paymentDay),
  };
};

const getDealDMInitialValues = (opportunity: IOpportunityShow): IOpportunityFormDealDMData => {
  const { dealManagement } = opportunity;

  return {
    actualCloseDate: getDateFieldInitialValue(dealManagement.actualCloseDate),
    analystComments: getTextFieldInitialValue(dealManagement.analystComments),
    borrowerTaxId: getTextFieldInitialValue(dealManagement.borrowerTaxId),
    broker: getAutocompleteFieldInitialValue(dealManagement.broker),
    dhcExternalSuccessorBorrower: getAutocompleteFieldInitialValue(dealManagement.dhcExternalSuccessorBorrower),
    circleDate: getDateFieldInitialValue(dealManagement.circleDate),
    closeDateNotes: getTextFieldInitialValue(dealManagement.closeDateNotes),
    counselFirmServicer: getAutocompleteFieldInitialValue(dealManagement.counselFirmServicer),
    externalSuccessorBorrower: getTextFieldInitialValue(dealManagement.externalSuccessorBorrower),
    isCollectSbFee: getBooleanFieldInitialValue(dealManagement.isCollectSbFee),
    lastDraftClosingStatementDate: getDateFieldInitialValue(dealManagement.lastDraftClosingStatementDate),
    lastEngagementLetterSendDate: getDateFieldInitialValue(dealManagement.lastEngagementLetterSendDate),
    managerComments: getTextFieldInitialValue(dealManagement.managerComments),
    purchaseRefi: getTextFieldInitialValue(dealManagement.purchaseRefi),
    receivedDate: getDateFieldInitialValue(dealManagement.receivedDate),
    securitiesType: getSelectFieldInitialValue(dealManagement.securitiesType),
    wellsFargoCustodian: getTextFieldInitialValue(dealManagement.wellsFargoCustodian),
    noticeSentDate: getDateFieldInitialValue(dealManagement.noticeSentDate),
    depositSentDate: getDateFieldInitialValue(dealManagement.depositSentDate),
    wplSentDate: getDateFieldInitialValue(dealManagement.wplSentDate),
    kickoffCallDate: getDateFieldInitialValue(dealManagement.kickoffCallDate),
    closingStatementSentDate: getDateFieldInitialValue(dealManagement.closingStatementSentDate),
    securitiesAuthDate: getDateFieldInitialValue(dealManagement.securitiesAuthDate),
    servicersLetterDate: getDateFieldInitialValue(dealManagement.servicersLetterDate),
    accountantReportDate: getDateFieldInitialValue(dealManagement.accountantReportDate),
    isRatingAgencyConfirmed: getBooleanFieldInitialValue(dealManagement.isRatingAgencyConfirmed),
    successorBorrowerEmployerIdentificationNumber: getTextFieldInitialValue(dealManagement.successorBorrowerEmployerIdentificationNumber),
  };
};

const getTransactionCostInitialValues = (opportunity: IOpportunityShow): IOpportunityTransactionCost => {
  const { loan, transactionCost } = opportunity;
  const isMonthlyPaymentOverDisabled = !loan.isIrregularPayments;
  return {
    ...transactionCost,
    securitiesUsed: getSelectFieldInitialValue(transactionCost.securitiesUsed),
    tsy1: getPercentFieldInitialValue(transactionCost.tsy1),
    tsy2: getPercentFieldInitialValue(transactionCost.tsy2),
    tsy3: getPercentFieldInitialValue(transactionCost.tsy3),
    tsy5: getPercentFieldInitialValue(transactionCost.tsy5),
    tsy10: getPercentFieldInitialValue(transactionCost.tsy10),
    monthlyPaymentOvr: isMonthlyPaymentOverDisabled ? '0.00' : transactionCost.monthlyPaymentOvr,
    agenciesSecuritiesCost: getYieldFieldInitialValue(transactionCost.agenciesSecuritiesCost),
  };
};

const getTotalTransactionCostInitialValues = (opportunity: IOpportunityShow): IOpportunityTransactionCostDM => {
  const { totalTransactionCost } = opportunity;
  const closeDateInitialValue = getDateFieldInitialValue(opportunity.estimatedCloseDate);
  return {
    ...totalTransactionCost,
    totalTransactionOpb1Date: DateHelpers.toReadable(DateHelpers.toDateWithFirstDayOfMonth(closeDateInitialValue)),
    totalTransactionOpb2Date: DateHelpers.addMonths(DateHelpers.toDateWithFirstDayOfMonth(closeDateInitialValue), 1),
    totalTransactionOpb3Date: DateHelpers.addMonths(DateHelpers.toDateWithFirstDayOfMonth(closeDateInitialValue), 2),
  };
};

const getPropertyInitialValues = (property: IOpportunityProperty): IOpportunityProperty => ({
  ...property,
  name: getTextFieldInitialValue(property.name),
  ownershipEntity: getTextFieldInitialValue(property.ownershipEntity),
  subtype: getTextFieldInitialValue(property.subtype),
  generalLedgerAccountNumber: getTextFieldInitialValue(property.generalLedgerAccountNumber),
  type: getSelectFieldInitialValue(property.type),

  assessorParcelNumber: getTextFieldInitialValue(property.assessorParcelNumber),
  website: getTextFieldInitialValue(property.website),
});

export const getOpbDatesInitialValues = (opportunity: IOpportunityShow): Array<IOpportunityOpbDate> => {
  const areEmptyOpbDates = getOpbDatesEmpty(opportunity);
  if (areEmptyOpbDates) {
    const closeDateInitialValue = getDateFieldInitialValue(opportunity.estimatedCloseDate);
    const opbDatesLength = opportunity.opbDates.length || 7;
    return new Array(opbDatesLength).fill('').map((item, index) => ({
      date: closeDateInitialValue
        ? DateHelpers.addMonths(
          DateHelpers.toDateWithDayOfMonth(closeDateInitialValue, Number(opportunity.loan.paymentDay) || 1),
          index - 1,
          false,
        )
        : null,
      value: item?.value || '0.00',
    }));
  }

  return opportunity.opbDates.map((item) => ({
    date: DateHelpers.toReadable(item.date),
    value: item.value,
  }));
};

export const getLoanLookupInitialValues = (
  loanLookup: IOpportunityLoanLookupIndexWithId,
): IOpportunityLoanLookupIndexWithId => {
  const { loan } = loanLookup;
  return {
    ...loanLookup,
    loan: {
      ...loan,
      coupon: String(Number(loan.coupon)),
      loanNumber: Number(loan.loanNumber),
      loanAmortization: String(Number(loan.loanAmortization)),
    },
  };
};

const getCredIQFinancialInitialValues = (opportunity: IOpportunityShow): ICredIQFinancial => {
  const { moodysFinancial } = opportunity;
  if (isBlank(moodysFinancial)) {
    return null;
  }
  return {
    mostRecentRevenue: getNumberFieldInitialValue(moodysFinancial.mostRecentRevenue),
    mostRecentOperatingExpenses: getNumberFieldInitialValue(moodysFinancial.mostRecentOperatingExpenses),
    mostRecentNoi: getNumberFieldInitialValue(moodysFinancial.mostRecentNoi),
    annualizedNoi: getNumberFieldInitialValue(moodysFinancial.annualizedNoi),
    estimatedCapRate: getEditablePercentFieldInitialValue(moodysFinancial.estimatedCapRate),
    estimatedCapRateAtContribution: getEditablePercentFieldInitialValue(moodysFinancial.estimatedCapRateAtContribution),
    remainingPeriodsUntilFirstDefeasible: getNumberFieldInitialValue(moodysFinancial.remainingPeriodsUntilFirstDefeasible),
    remainingLockout: getNumberFieldInitialValue(moodysFinancial.remainingLockout),
    remainingYieldMaintenance: getNumberFieldInitialValue(moodysFinancial.remainingYieldMaintenance),
    remainingPenalty: getNumberFieldInitialValue(moodysFinancial.remainingPenalty),
    mostRecentValue: getNumberFieldInitialValue(moodysFinancial.mostRecentValue),
    ltvAtContribution: getEditablePercentFieldInitialValue(moodysFinancial.ltvAtContribution),
    annualizedNoiEditable: getNumberFieldInitialValue(moodysFinancial.annualizedNoiEditable),
    estimatedCapRateEditable: getEditablePercentFieldInitialValue(moodysFinancial.estimatedCapRateEditable),
    estimatedCapRateAtContributionEditable: getEditablePercentFieldInitialValue(moodysFinancial.estimatedCapRateAtContributionEditable),
    ltvAtContributionEditable: getEditablePercentFieldInitialValue(moodysFinancial.ltvAtContributionEditable),
    mostRecentNoiEditable: getNumberFieldInitialValue(moodysFinancial.mostRecentNoiEditable),
    mostRecentOperatingExpensesEditable: getNumberFieldInitialValue(moodysFinancial.mostRecentOperatingExpensesEditable),
    mostRecentRevenueEditable: getNumberFieldInitialValue(moodysFinancial.mostRecentRevenueEditable),
    mostRecentValueEditable: getNumberFieldInitialValue(moodysFinancial.mostRecentValueEditable),
    remainingLockoutEditable: getNumberFieldInitialValue(moodysFinancial.remainingLockoutEditable),
    remainingPrepaymentPenaltyEditable: getNumberFieldInitialValue(moodysFinancial.remainingPrepaymentPenaltyEditable),
    remainingPeriodsUntilFirstDefeasibleEditable: getNumberFieldInitialValue(moodysFinancial.remainingPeriodsUntilFirstDefeasibleEditable),
    remainingYieldMaintenanceEditable: getNumberFieldInitialValue(moodysFinancial.remainingYieldMaintenanceEditable),
    moodysPropertyName: getTextFieldInitialValue(moodysFinancial.moodysPropertyName),
  };
};

export const getInitialValues = (
  opportunity: IOpportunityShow,
  options: IOptions,
  pricingDetailList: Array<IPricingDetail>,
): OpportunityFormData => {
  if (isBlank(opportunity)) {
    return null;
  }

  const lastPricingDetail = !isNil(pricingDetailList) && last(pricingDetailList);

  return {
    ...opportunity,
    name: getTextFieldInitialValue(opportunity.name),
    loan: getLoanInitialValues(opportunity),
    newPricingDetail: getPricingDetailInitialValues(
      opportunity.newPricingDetail,
      options,
      opportunity.id,
      lastPricingDetail,
    ),
    property: getPropertyInitialValues(opportunity.property),
    transactionCost: getTransactionCostInitialValues(opportunity),
    totalTransactionCost: getTotalTransactionCostInitialValues(opportunity),
    account: getAutocompleteFieldInitialValue(opportunity.account),
    originator: getAutocompleteFieldInitialValue(opportunity.originator),
    dealManager: getAutocompleteFieldInitialValue(opportunity.dealManager),
    salesDevelopmentRepresentative: getAutocompleteFieldInitialValue(opportunity.salesDevelopmentRepresentative),
    estimatedCloseDate: getDateFieldInitialValue(opportunity.estimatedCloseDate),
    probability: getSelectFieldInitialValue(opportunity.probability),
    stage: getSelectFieldInitialValue(opportunity.stage),
    deal: getTextFieldInitialValue(opportunity.deal),
    dealManagement: getDealDMInitialValues(opportunity),
    additionalComments: getTextFieldInitialValue(opportunity.additionalComments),
    comments: getTextFieldInitialValue(opportunity.comments),
    calculatedBy: getTextFieldInitialValue(opportunity?.transactionCost.calculatedBy?.fullName),
    calculatedAt: getTextFieldInitialValue(
      DateHelpers.dateAndTimeFormatted(DateHelpers.toDate(opportunity?.transactionCost.calculatedAt)),
    ),
    opbDates: getOpbDatesInitialValues(opportunity),
    areOpbDatesEmpty: getOpbDatesEmpty(opportunity),
    encodedSharepointUrl: getTextFieldInitialValue(opportunity?.encodedSharepointUrl),
    moodysFinancial: getCredIQFinancialInitialValues(opportunity),
  };
};

export const serializeMoneyField = (moneyField: string): string => moneyField || '0.00';

export const serialize = (opportunityFormData: OpportunityFormData): OpportunityFormSerializedData => {
  const {
    loan,
    dealManagement,
    property,
    transactionCost,
    totalTransactionCost,
    opbDates,
    miscellaneousFees,
    moodysFinancial,
    ...restValues
  } = opportunityFormData;

  return {
    ...restValues,
    accountId: opportunityFormData.account ? opportunityFormData.account.id : null,
    originatorId: opportunityFormData.originator ? opportunityFormData.originator.id : null,
    dealManagerId: opportunityFormData.dealManager ? opportunityFormData.dealManager.id : null,
    salesDevelopmentRepresentativeId: opportunityFormData.salesDevelopmentRepresentative
      ? opportunityFormData.salesDevelopmentRepresentative.id
      : null,
    ownerId: opportunityFormData.owner ? opportunityFormData.owner.id : null,
    estimatedCloseDate: DateHelpers.toIso(opportunityFormData.estimatedCloseDate),
    deal: opportunityFormData.deal || null,
    probability: opportunityFormData.probability || null,
    stage: opportunityFormData.stage || null,
    additionalComments: opportunityFormData.additionalComments || null,
    analystComments: opportunityFormData.analystComments || '',
    managerComments: opportunityFormData.managerComments || '',
    comments: opportunityFormData.comments || null,
    loan: {
      ...loan,
      originalLoanBalance: loan.originalLoanBalance || null,
      loanTerm: loan.loanTerm || null,
      monthlyDebtService: loan.monthlyDebtService || null,
      firstPiPaymentDate: DateHelpers.toIso(loan.firstPiPaymentDate),
      maturityDate: DateHelpers.toIso(loan.maturityDate) || null,
      interestCalcMethod: loan.interestCalcMethod || null,
      paymentDay: loan.paymentDay || null,
      interestOnlyPeriods: loan.interestOnlyPeriods || null,
      permittedDate: DateHelpers.toIso(loan.permittedDate) || null,
      loanNumber: loan.loanNumber || null,
      coupon: loan.coupon || null,
      subLoanNumber: loan.subLoanNumber || null,
      loanAmortization: loan.loanAmortization || null,
      noteDated: DateHelpers.toIso(loan.noteDated),
      servicerId: loan.servicer?.id || null,
      subServicerId: loan.subServicer?.id || null,
      poolId: loan.pool?.id || null,
      multipackId: loan.multipack?.id || null,
      successorBorrowerCompanyId: loan.successorBorrowerCompany?.id || null,
      calculatedBalloonPayment: serializeMoneyField(loan.calculatedBalloonPayment),
      canadianInterestRate: loan.canadianInterestRate || null,
    },
    dealManagement: {
      ...dealManagement,
      borrowerTaxId: dealManagement.borrowerTaxId || null,
      closeDateNotes: dealManagement.closeDateNotes || null,
      managerComments: dealManagement.managerComments || '',
      analystComments: dealManagement.analystComments || '',
      wellsFargoCustodian: dealManagement.wellsFargoCustodian || null,
      purchaseRefi: dealManagement.purchaseRefi || null,
      counselFirmServicerId: dealManagement.counselFirmServicer?.id || null,
      externalSuccessorBorrower: dealManagement.externalSuccessorBorrower || null,
      dhcExternalSuccessorBorrowerId: dealManagement.dhcExternalSuccessorBorrower?.id || null,
      lastEngagementLetterSendDate: DateHelpers.toIso(dealManagement.lastEngagementLetterSendDate) || null,
      receivedDate: DateHelpers.toIso(dealManagement.receivedDate) || null,
      lastDraftClosingStatementDate: DateHelpers.toIso(dealManagement.lastDraftClosingStatementDate) || null,
      circleDate: DateHelpers.toIso(dealManagement.circleDate) || null,
      actualCloseDate: DateHelpers.toIso(dealManagement.actualCloseDate) || null,
      securitiesType: dealManagement.securitiesType || null,
      noticeSentDate: DateHelpers.toIso(dealManagement.noticeSentDate) || null,
      depositSentDate: DateHelpers.toIso(dealManagement.depositSentDate) || null,
      wplSentDate: DateHelpers.toIso(dealManagement.wplSentDate) || null,
      kickoffCallDate: DateHelpers.toIso(dealManagement.kickoffCallDate) || null,
      closingStatementSentDate: DateHelpers.toIso(dealManagement.closingStatementSentDate) || null,
      securitiesAuthDate: DateHelpers.toIso(dealManagement.securitiesAuthDate) || null,
      servicersLetterDate: DateHelpers.toIso(dealManagement.servicersLetterDate) || null,
      accountantReportDate: DateHelpers.toIso(dealManagement.accountantReportDate) || null,
      successorBorrowerEmployerIdentificationNumber: dealManagement.successorBorrowerEmployerIdentificationNumber || null,
    },
    property: {
      ...property,
      name: property.name || null,
      ownershipEntity: property.ownershipEntity || null,
      subtype: property.subtype || null,
      generalLedgerAccountNumber: property.generalLedgerAccountNumber || null,
      assessorParcelNumber: property.assessorParcelNumber || null,
      website: property.website || null,
      type: property.type || null,
    },
    transactionCost: {
      ...transactionCost,
      securitiesUsed: transactionCost.securitiesUsed || null,
      monthlyPaymentOvr: serializeMoneyField(transactionCost.monthlyPaymentOvr),
      ratingAgencyFee: serializeMoneyField(transactionCost.ratingAgencyFee),
      servicerDeposit: serializeMoneyField(transactionCost.servicerDeposit),
      servicerProcessing: serializeMoneyField(transactionCost.servicerProcessing),
      specialServicer: serializeMoneyField(transactionCost.specialServicer),
      custodian: serializeMoneyField(transactionCost.custodian),
      servicerLegalFees: serializeMoneyField(transactionCost.servicerLegalFees),
      securitiesPurchaseFees: serializeMoneyField(transactionCost.securitiesPurchaseFees),
      accountantFee: serializeMoneyField(transactionCost.accountantFee),
      successorBorrowerFee: serializeMoneyField(transactionCost.successorBorrowerFee),
      cdfDeposit: serializeMoneyField(transactionCost.cdfDeposit),
      cdfAdvisory: serializeMoneyField(transactionCost.cdfAdvisory),
      securitiesCost: serializeMoneyField(transactionCost.securitiesCost),
      agenciesSecuritiesCost: serializeMoneyField(transactionCost.agenciesSecuritiesCost),
      opbDefeasance: serializeMoneyField(transactionCost.opbDefeasance),
      tsy1: serializeMoneyField(transactionCost.tsy1),
      tsy2: serializeMoneyField(transactionCost.tsy2),
      tsy3: serializeMoneyField(transactionCost.tsy3),
      tsy5: serializeMoneyField(transactionCost.tsy5),
      tsy10: serializeMoneyField(transactionCost.tsy10),
    },
    totalTransactionCost: {
      ...totalTransactionCost,
      totalTransactionFinalSecuritiesCost: serializeMoneyField(
        totalTransactionCost.totalTransactionFinalSecuritiesCost,
      ),
      totalTransactionCustodianFee: serializeMoneyField(totalTransactionCost.totalTransactionCustodianFee),
      totalTransactionAccountantFee: serializeMoneyField(totalTransactionCost.totalTransactionAccountantFee),
      totalTransactionServicerCounselFee: serializeMoneyField(totalTransactionCost.totalTransactionServicerCounselFee),
      totalTransactionSuccessorBorrowerLegal: serializeMoneyField(
        totalTransactionCost.totalTransactionSuccessorBorrowerLegal,
      ),
      totalTransactionSuccessorBorrower: serializeMoneyField(totalTransactionCost.totalTransactionSuccessorBorrower),
      totalTransactionCdfAdvisoryFee: serializeMoneyField(totalTransactionCost.totalTransactionCdfAdvisoryFee),
      totalTransactionCdfDeposit: serializeMoneyField(totalTransactionCost.totalTransactionCdfDeposit),
      totalTransactionExpectedFee: serializeMoneyField(totalTransactionCost.totalTransactionExpectedFee),
      totalTransactionRatingAgencyFee: serializeMoneyField(totalTransactionCost.totalTransactionRatingAgencyFee),
      totalTransactionSpecialServicerFee: serializeMoneyField(totalTransactionCost.totalTransactionSpecialServicerFee),
      totalTransactionSecuritiesPurchaseFee: serializeMoneyField(totalTransactionCost.totalTransactionSecuritiesPurchaseFee),
      totalTransactionServicerDeposit: serializeMoneyField(totalTransactionCost.totalTransactionServicerDeposit),
      totalTransactionServicerProcessing: serializeMoneyField(totalTransactionCost.totalTransactionServicerProcessing),
      totalTransactionEscrowReserve: serializeMoneyField(totalTransactionCost.totalTransactionEscrowReserve),
    },
    opbDates: opbDates.map((opbDate) => ({
      date: DateHelpers.toIsoWithoutUTC(opbDate.date),
      value: serializeMoneyField(opbDate.value),
    })),
    miscellaneousFees: miscellaneousFees.map((fee) => ({
      ...fee,
      description: isBlank(fee.description) ? null : fee.description,
    })),
    moodysFinancial: {
      ...moodysFinancial,
      remainingPeriodsUntilFirstDefeasible: isBlank(moodysFinancial.remainingPeriodsUntilFirstDefeasible) ? null : moodysFinancial.remainingPeriodsUntilFirstDefeasible,
      remainingLockout: isBlank(moodysFinancial.remainingLockout) ? null : moodysFinancial.remainingLockout,
      remainingYieldMaintenance: isBlank(moodysFinancial.remainingYieldMaintenance) ? null : moodysFinancial.remainingYieldMaintenance,
      remainingPenalty: isBlank(moodysFinancial.remainingPenalty) ? null : moodysFinancial.remainingPenalty,
      remainingLockoutEditable: isBlank(moodysFinancial.remainingLockoutEditable) ? null : moodysFinancial.remainingLockoutEditable,
      remainingPrepaymentPenaltyEditable: isBlank(moodysFinancial.remainingPrepaymentPenaltyEditable) ? null : moodysFinancial.remainingPrepaymentPenaltyEditable,
      remainingPeriodsUntilFirstDefeasibleEditable: isBlank(moodysFinancial.remainingPeriodsUntilFirstDefeasibleEditable) ? null : moodysFinancial.remainingPeriodsUntilFirstDefeasibleEditable,
      remainingYieldMaintenanceEditable: isBlank(moodysFinancial.remainingYieldMaintenanceEditable) ? null : moodysFinancial.remainingYieldMaintenanceEditable,
      moodysPropertyName: isEmpty(moodysFinancial.moodysPropertyName) ? null :  moodysFinancial.moodysPropertyName,
    }
  };
};

export const validationSchema = yup.object({
  account: yup.object().nullable().required('validation:reqiuredAccount'),
  accountId: yup.number().nullable(),
  name: yup.string().trim().required('validation:reqiuredName'),
  owner: yup.object().nullable().required('validation:reqiuredOwner'),
  estimatedCloseDate: dateValidationRule.nullable().required('validation:requiredEstCloseDate'),
  originator: yup.object().nullable().required('validation:reqiuredOriginator'),
  loan: yup.object({
    maturityDate: dateValidationRule.nullable(),
    firstPiPaymentDate: dateValidationRule.nullable(),
    loanTerm: loanTermValidationRule.integer(),
    interestOnlyPeriods: yup.number().nullable().integer().typeError('validation:integerFieldError'),
    permittedDate: dateValidationRule.nullable(),
    noteDated: dateValidationRule.nullable(),
    loanAmortization: yup.string().max(16, 'validation:loanAmortizationLength').nullable(),
    paymentDay: paymentDayValidationRule,
  }),
  dealManagement: yup.object({
    actualCloseDate: dateValidationRule.nullable(),
    circleDate: dateValidationRule.nullable(),
    lastDraftClosingStatementDate: dateValidationRule.nullable(),
    lastEngagementLetterSendDate: dateValidationRule.nullable(),
    receivedDate: dateValidationRule.nullable(),
    noticeSentDate: dateValidationRule.nullable(),
    depositSentDate: dateValidationRule.nullable(),
    wplSentDate: dateValidationRule.nullable(),
    kickoffCallDate: dateValidationRule.nullable(),
    closingStatementSentDate: dateValidationRule.nullable(),
    servicersLetterDate: dateValidationRule.nullable(),
    accountantReportDate: dateValidationRule.nullable(),
    securitiesAuthDate: dateValidationRule.nullable(),
  }),
});
