import { ReactNode } from 'react';

import { ConfirmationVariant } from 'enums/components/confirmation/variants';

import { Close as CloseIcon } from '@mui/icons-material';
import {
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Button,
  IconButton,
} from '@mui/material';

import Box from 'components/common/Box';

import style from './style';

type ConfirmationProps = {
  isOpen: boolean;
  title: string;
  text: string | ReactNode;
  variant?: ConfirmationVariant;
  disabled?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onClose: () => void;
  onConfirm: () => void;
};

const Confirmation: FC<ConfirmationProps> = (props) => {
  const {
    isOpen,
    disabled,
    title,
    text,
    variant = ConfirmationVariant.default,
    cancelButtonText = 'Cancel',
    confirmButtonText = 'Confirm',
    onClose,
    onConfirm,
  } = props;
  return (
    <Dialog open={isOpen} onClose={onClose} data-test-id='unsavedDialog'>
      <DialogTitle sx={style.title}>
        <Grid container justifyContent='space-between' alignItems='center' wrap='nowrap'>
          <Grid item>{title}</Grid>
          <Grid item>
            <IconButton onClick={onClose} size='large'>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <Box sx={style.wrapper}>
        <DialogContent sx={style.content}>
          <DialogContentText align='left' color='textPrimary'>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='text' color='primary' disabled={disabled} onClick={onClose} data-test-id='doNotSaveButton'>
            {cancelButtonText}
          </Button>
          <Button variant='contained' disabled={disabled} onClick={onConfirm} color={variant} autoFocus data-test-id='confirmButton'>
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default Confirmation;
