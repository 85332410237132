import { LayoutsOptions } from 'types/theme';

import { SxProps, Theme } from '@mui/material';
import { DeprecatedThemeOptions, createTheme as createMuiTheme } from '@mui/material/styles';

import breakpoints from './breakpoints';
import overrides from './overrides';
import palette from './palette';
import props from './props';
import shadows from './shadows';
import typography from './typography';

const createTheme = (
  themeOptions: DeprecatedThemeOptions & { layouts: LayoutsOptions },
  overridesFunction?: OverrideWithTheme,
) => {
  const baseTheme = createMuiTheme(themeOptions);
  return !overridesFunction
    ? baseTheme
    : createMuiTheme({
      ...baseTheme,
      components: { ...overridesFunction(baseTheme) },
    });
};

export const layoutsOptions: LayoutsOptions = {
  baseLayout: {
    contentOffsetFromHeader: 32,
    header: {
      height: 48,
    },
  },
};

const theme = createTheme(
  {
    palette,
    props,
    shadows,
    breakpoints,
    typography,
    layouts: layoutsOptions,
  },
  overrides,
);

export type SxStyles = Record<string, SxProps<Theme>>;

export default theme;
