import { useCallback, useState } from 'react';

import { Grid, MenuList, MenuItem, Paper } from '@mui/material';
import muiSx from 'mui-sx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { startsWith } from 'ramda';
import { appRoutes } from 'routes';

import Box from 'components/common/Box';

import styles from './styles';

const navigationItems = [
  {
    pathname: appRoutes.opportunitiesPath(),
    label: 'Opportunities',
  },
  {
    pathname: appRoutes.accountsPath(),
    label: 'Accounts',
  },
  {
    pathname: appRoutes.contactsPath(),
    label: 'Contacts',
  },
  {
    pathname: appRoutes.resourcesPath(),
    label: 'Resources',
    children: [
      {
        pathname: appRoutes.resourcesPoolsPath(),
        label: 'Pools',
      },
      {
        pathname: appRoutes.resourcesMultipacksPath(),
        label: 'Multipacks',
      },
      {
        pathname: appRoutes.resourcesSuccessorBorrowersPath(),
        label: 'Successor Borrowers',
      },
    ],
  },
  {
    pathname: appRoutes.yardiServicePath(),
    label: 'Yardi service',
  },
  {
    pathname: appRoutes.credIQPath(),
    label: "CredIQ",
  },
  {
    pathname: appRoutes.reportsPath(),
    label: "Reports",
  },
];

const MainNavigation: FC = () => {
  
  const [visible, setVisible] = useState<boolean>(false);
  const router = useRouter();
  const { pathname: currentPathname } = router;

  const handleHideSubMenu = useCallback(() => {
    setVisible(false);
  }, []);

  const handleShowSubMenu = useCallback(() => {
    setVisible(true);
  }, []);

  return (
    <Grid container spacing={2} alignItems='center' justifyContent='center'>
      {navigationItems.map((navigationItem) => (
        <Grid
          key={navigationItem.pathname}
          item
          sx={styles.rootItem}
          onMouseEnter={navigationItem.children ? handleShowSubMenu : () => {}}
          onMouseLeave={handleHideSubMenu}
        >
          <Box 
            sx={muiSx(styles.link, {
              sx: styles.active,
              condition: startsWith(navigationItem.pathname, currentPathname),
            })}
            data-test-id='navigationItem'
          >
            <Link href={navigationItem.pathname}>{navigationItem.label}</Link>
          </Box>
          {navigationItem.children && (
            <Box
              sx={muiSx(styles.subMenuContainer, {
                sx: styles.subMenuContainerShow,
                condition: visible,
              })}
            >
              <Paper sx={styles.paper}>
                <MenuList sx={styles.subMenu}>
                  {navigationItem.children.map((subItem) => (
                    <MenuItem
                      key={subItem.pathname}
                      sx={muiSx(styles.subMenuItem, {
                        sx: styles.activeSubMenuItem,
                        condition: startsWith(subItem.pathname, currentPathname),
                      })}
                      onClick={handleHideSubMenu}
                      data-test-id='navigationMenuItem'
                    >
                      <Link href={subItem.pathname}>{subItem.label}</Link>
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            </Box>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default MainNavigation;
