import { SxStyles } from 'theme';

const styles: SxStyles = {
  link: {
    color: 'white',
    fontSize: theme => theme.typography.pxToRem(13),
    opacity: 0.7,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  active: {
    opacity: 1,
    fontWeight: 700,
  },
  rootItem: {
    position: 'relative',
    zIndex: 2,
    '&:hover $subMenuContainer': {
      display: 'block',
    },

    '&:first-child': {
      paddingLeft: 4,
    },
  },
  subMenuContainer: {
    display: 'none',
    position: 'absolute',
    paddingTop: 3,
  },
  subMenuContainerShow: {
    display: 'block',
    paddingTop: 3,
  },
  paper: {
    boxShadow: 'none',
    border: theme => `1px solid ${theme.palette.catskillWhite}`,
  },
  subMenu: {
    padding: 0,
    outline: 'none',
  },
  subMenuItem: {
    padding: theme => theme.spacing(2),
    paddingBottom: theme => theme.spacing(1.25),
    paddingTop: theme => theme.spacing(1.25),
    minHeight: 20,
  },
  activeSubMenuItem: {
    color: 'primary.main',
  },
};

export default styles;
