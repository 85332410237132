const AutocompleteOverrides: OverrideWithTheme = (theme) => ({
  MuiAutocomplete: {
    styleOverrides: {
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          padding: '0 8px 0 0',

          '& .MuiAutocomplete-input': {
            paddingTop: 0,
            paddingBottom: 0,
            lineHeight: theme.typography.pxToRem(24),
            '&:first-child': {
              paddingLeft: 10,
            },
          },
          '&[class*="MuiOutlinedInput-marginDense"]': {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
          },
        },
      },
    },
  },
});

export default AutocompleteOverrides;
