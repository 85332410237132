import { 
  accountsApi, 
  useCreateAccountMutation, 
  useDeleteAccountMutation, 
  useLazyGetAccountQuery, 
  useLazyGetAccountsQuery, 
  useUpdateAccountMutation,
} from 'domain/account/apiSlice';
import { IAccountIndex, IAccountItem } from 'domain/account/types';
import { useDispatch } from 'react-redux';

type UseAccount = {
  getAccounts: (params?) => void;
  resetAccountList: () => void;
  accountsList: Array<IAccountIndex>;
  isAccountsLoading: boolean;
  isAccountsFetching: boolean;
  nextPage: boolean;
  createAccount: (params?) => void;
  createdAccount: IAccountItem;
  isCreatedAccountLoading: boolean;
  account: IAccountItem;
  getAccount: (id: number) => void;
  isAccountLoading: boolean;
  accountError: unknown;
  updateAccount: (params: QueryParams) => void;
  isUpdateAccountLoading: boolean;
  accountUpdateError: unknown;
  deleteAccount: (id: number) => void;
};

const useAccount = (): UseAccount => {
  const dispatch = useDispatch();

  const [ getAccounts, { data: accountsResponse, isLoading: isAccountsLoading, isFetching: isAccountsFetching } ] = useLazyGetAccountsQuery();
  const [ createAccount, {data: createdAccount, isLoading: isCreatedAccountLoading} ] = useCreateAccountMutation();
  const [ getAccount, { data: accountResponse, isLoading: isAccountLoading, error: accountError } ] = useLazyGetAccountQuery();
  const [ updateAccount, { isLoading: isUpdateAccountLoading, error: accountUpdateError } ] = useUpdateAccountMutation();
  const [ deleteAccount ] = useDeleteAccountMutation();

  const accountsList = accountsResponse?.response;

  const nextPage = accountsResponse?.next;

  const account = accountResponse?.response;

  const resetAccountList = () => dispatch(accountsApi.util.resetApiState());

  return {
    getAccounts,
    resetAccountList,
    accountsList,
    isAccountsLoading,
    isAccountsFetching,
    nextPage,
    createAccount,
    createdAccount,
    isCreatedAccountLoading,
    account,
    getAccount,
    isAccountLoading, 
    accountError,
    updateAccount,
    isUpdateAccountLoading,
    accountUpdateError,
    deleteAccount,
  };
};

export default useAccount;
