import humps from 'humps';

export const toResourceName = (actionName: string, resource: string): string => {
  const capName = humps.pascalize(resource);
  return actionName.replace(capName, 'Resource');
};

export const toSliceName = (actionName: string, resource: string): string =>
  `${toResourceName(actionName, resource)}Slice`;

export const toRealName = (actionName: string, resource: string): string => {
  const capName = humps.pascalize(resource);
  return actionName.replace('Resource', capName);
};

export const keysToRealName =
  (resource: string) =>
  <T>(object: T): Record<string, unknown> =>
      Object.keys(object).reduce((result, key) => ({ ...result, [toRealName(key, resource)]: object[key] }), {});
