import { BreakpointOverrides } from '@mui/material/styles';

const breakpoints: BreakpointOverrides = {
  values: {
    xs: 0,
    md: 968,
    lg: 1340,
  },
};

export default breakpoints;
