const RadioOverrides: OverrideWithTheme = (theme) => ({
  MuiRadio: {
    styleOverrides: {
      root: {
        color: theme.palette.catskillWhite,
      },
    },
  },
});

export default RadioOverrides;
