import { createContext } from 'react';

import { useBreadcrumbs } from 'hooks/useBreadcrumbs';

export const BreadcrumbsContext = createContext(null);

const BreadcrumbsProvider: FC = (props) => {
  const { children } = props;
  const breadcrumbsState = useBreadcrumbs();

  return <BreadcrumbsContext.Provider value={breadcrumbsState}>{children}</BreadcrumbsContext.Provider>;
};

export default BreadcrumbsProvider;
