import { RedirectRules, RedirectRule } from 'routes/redirectRules';

import { NextRouter } from 'next/router';
import { find, includes, propEq } from 'ramda';
import { appRoutes } from 'routes';

const performClientSideRedirect = (destination: string, nextRouter: NextRouter) => {
  nextRouter.push(destination);
};

export const initializeRedirects = (router: NextRouter, redirectRules: RedirectRules): void => {
  const { pathname, asPath } = router;
  const currentUrl = pathname === appRoutes.pageNotFoundPath() ? asPath : pathname;
  const pathsForRedirect = redirectRules.map(({ from }) => from);
  const shouldPerformRedirect = includes(currentUrl, pathsForRedirect);

  if (!shouldPerformRedirect) {
    return;
  }

  const { to: targetPath } = find<RedirectRule>(propEq('from', currentUrl))(redirectRules);

  performClientSideRedirect(targetPath, router);
};
