const OutlinedInputOverrides: OverrideWithTheme = (theme) => ({
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '&:hover .Mui-notchedOutline': {
          borderColor: theme.palette.santasGray,
        },
        '&.Mui-disabled': {
          '& .Mui-notchedOutline': {
            borderColor: theme.palette.blackSqueeze,
          },
          '& fieldset': {
            background: 'none !important',
          },
        },
      },
      input: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        background: theme.palette.athensGray,
        '&:focus': {
          background: theme.palette.white,
        },
      },
      multiline: {
        padding: 0,
        '& > textarea': {
          padding: [0, 12],
        },
      },
      notchedOutline: {
        borderWidth: 2,
        borderColor: theme.palette.catskillWhite,
      },
      adornedEnd: {
        paddingRight: 14,
      },
    },
  },
});

export default OutlinedInputOverrides;
