import { SxStyles } from 'theme';

const styles: SxStyles = {
  userMenuItem: {
    minHeight: 0,
    minWidth: 150,
    position: 'relative',

    '& a': {
      width: '100%',
    },
  },
  loadingProgressBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    margin: 0,
    marginLeft: 2,
  },
};

export default styles;
