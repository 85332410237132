import { destroyCookie, parseCookies, setCookie } from 'nookies';

enum CookieKeys {
  accessToken = 'accessToken',
}

type CookiesOptions = {
  path: string;
  maxAge?: number;
};

const set = (key: string, value: string, options?: CookiesOptions): void => {
  setCookie(null, key, value, options);
};

const get = (key: string): string | undefined => parseCookies(null, key)[key];

const destroy = (key: string): void => {
  destroyCookie(null, key, { path: '/' });
};

export const CookieHelper = {
  setAccessToken: (accessToken: string): void => {
    set(CookieKeys.accessToken, accessToken, { path: '/' });
  },
  getAccessToken: (): string => get(CookieKeys.accessToken),
  destroyAccessToken: (): void => {
    destroy(CookieKeys.accessToken);
  },
};
