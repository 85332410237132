import { ResourceSlice, SliceCallback, SliceConfig, SliceOptions } from 'types/slices';

import { isNil, map, mergeDeepRight, pipe, pluck, reduce, reject } from 'ramda';

export const validateConfig = (config: SliceConfig, availableSlices: ResourceSlice): void => {
  if (!config.resource) {
    throw new Error('REST Slice: Please define the resource name');
  }
  if (!config.repository) {
    throw new Error('REST Slice: Please define the resource repository object');
  }

  if (!config.slices || config.slices.length === 0) {
    const availableText = Object.keys(availableSlices)
      .map((k) => k.replace('Resource', '[Resource]'))
      .join(', ');
    throw new Error(`REST Slice: Please define slices for use. Available are: ${availableText}`);
  }
};

export const populateOptions = (config: SliceConfig): SliceOptions => {
  const { resource, repository } = config;

  const pluralResource = `${resource}List`;

  return {
    resource,
    repository,
    pluralResource,
  };
};

export const combineSlicesBy = <R>(
  key: string,
  f?: SliceCallback,
): ((list: Record<string, unknown>[]) => Record<string, R>) => {
  const callback = f ? map(f) : (r) => r;
  return pipe(pluck(key), reject(isNil), callback, reduce(mergeDeepRight, {}));
};
