import { camelize, decamelize } from 'utils/keysConverter';

import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { stringify } from 'qs';
import { isNil, omit } from 'ramda';

export const axiosInstance = axios.create({
  paramsSerializer: (parameters) => stringify(parameters.params || parameters, { encode: false, arrayFormat: 'repeat' }),
  withCredentials: true,
  baseURL: '/api/v1',
});

export const axiosBaseQuery = (): BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
  },
  unknown,
  unknown
> => (
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await axiosInstance({
        url,
        method,
        data: !isNil(data) && decamelize(data),
        params: !isNil(params) && decamelize(params),
        headers,
        withCredentials: true,
      });
      const camelizedResult = camelize(result.data);
      return { data: { response: camelizedResult.data || camelizedResult.results, ...omit(['data', 'results'], camelizedResult) } };
    } catch (axiosError) {
      const error = axiosError as AxiosError;
      return {
        error: {
          response: {
            status: error.response?.status,
            data: camelize(error.response?.data) || error.message,
          },
        },
      };
    }
  }
);
