import { ResourceSlice, SliceOptions } from 'types/slices';

import { RequestState } from 'enums/requestState';

import { pathOr } from 'ramda';

const loadResourceListSlice = (options: SliceOptions): ResourceSlice => {
  const { pluralResource, repository } = options;

  return {
    initialState: {
      [pluralResource]: {
        loadingStatus: RequestState.isIdle,
        updatingStatus: RequestState.isIdle,
        items: [],
        next: null,
        previous: null,
        error: null,
      },
    },
    reducers: {
      loadResourceListStart(state) {
        state[pluralResource].loadingStatus = RequestState.isPending;
        state[pluralResource].items = [];
        state[pluralResource].error = null;
      },
      updateResourceListStart(state) {
        state[pluralResource].updatingStatus = RequestState.isPending;
        state[pluralResource].error = null;
      },
      loadResourceListSuccess(state, { payload }) {
        const { results, next, previous } = payload;
        state[pluralResource].loadingStatus = RequestState.isSuccess;
        state[pluralResource].updatingStatus = RequestState.isSuccess;
        state[pluralResource].next = next;
        state[pluralResource].items = previous ? [...state[pluralResource].items, ...results] : results;
      },
      loadResourceListFail(state, { payload }) {
        state[pluralResource].loadingStatus = RequestState.isFailure;
        state[pluralResource].updatingStatus = RequestState.isFailure;
        state[pluralResource].error = payload;
      },
    },
    actionCreators(restDispatch) {
      return {
        loadResourceList: async (queryParams) => {
          try {
            const page = pathOr(1, ['params', 'page'], queryParams);
            if (page <= 1) {
              restDispatch('loadResourceListStart');
            } else {
              restDispatch('updateResourceListStart');
            }

            const data = await repository.index(queryParams);
            restDispatch('loadResourceListSuccess', data);
          } catch (errors) {
            restDispatch('loadResourceListFail', { errors });
          }
        },
      };
    },
    abstractSelector: (state) => ({
      resourceList: state[pluralResource],
    }),
  };
};

export default loadResourceListSlice;
