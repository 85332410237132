import { HttpStatusCode } from 'enums/httpStatusCode';
import { SessionStorageKeys } from 'enums/sessionStorageKeys';

import { SessionStorage } from 'helpers/SessionStorage';

import { AxiosError } from 'axios';
import { keys, omit } from 'ramda';

import { decamelize } from './keysConverter';

export const isUnauthorizedError = (error: AxiosError): boolean =>
  error.response.status === HttpStatusCode.unauthorized;

export const isUnprocessedEntityError = (error): boolean =>
  error.response?.status === HttpStatusCode.unprocessedEntity;

export const isNotFoundError = (error: AxiosError): boolean => error.response?.status === HttpStatusCode.notFound;

export const isInternalServerError = (error: AxiosError): boolean =>
  error.response.status === HttpStatusCode.internalServer;

export const isBadGatewayError = (error: AxiosError): boolean => error.response.status === HttpStatusCode.badGateway;

export const getUnprocessedErrors = (errors: unknown, unprocessedError: string): Omit<unknown, string> => {
  const errorsList = JSON.parse(unprocessedError);
  const errorsListWithoutErrorsFromFormik = omit(keys(decamelize(errors)), errorsList);
  return errorsListWithoutErrorsFromFormik;
};

export const changeUnprocessedErrorsInStorage = (errors: unknown): void => {
  SessionStorage.remove(SessionStorageKeys.unprocessedEntityError);
  SessionStorage.set(SessionStorageKeys.unprocessedEntityError, JSON.stringify(errors));
};

export const getErrorStatusFromResponse = (error: AxiosError): number => error.response.status;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const getNonFieldErrorsFromData = (error: Record<string, any>): Array<string> => error.errors.nonFieldErrors;

export const isUnprocessedEntityStatus = (status: number): boolean =>
  status === HttpStatusCode.unprocessedEntity;
