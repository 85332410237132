export enum TaskPath {
  sharepointFolder = 'sharepoint-folder-tasks',
  sharepointFile = 'sharepoint-file-tasks',
  draftClosingLetterEmail = 'draft-closing-letter-email-tasks',
  engagementLetterEmail = 'engagement-letter-email-tasks',
  engagementInEmail = 'engagement-in-email-tasks',
  finalClosingLetterEmail = 'final-closing-letter-email-tasks',
  quoteLetterEmail = 'quote-letter-email-tasks',
  workingPartyListEmail = 'wpl-email-tasks',
  authorizationFormEmail = 'authorization-form-email-tasks',
  defeasanceClosingLetterEmail = 'defeasance-closing-letter-email-tasks',
  defeasanceClosingSummaryEmail = 'defeasance-closing-summary-email-tasks',
}
