import { ResourceSlice, SliceOptions } from 'types/slices';

import { RequestState } from 'enums/requestState';

const resetResourceSlice = (options: SliceOptions): ResourceSlice => {
  const { resource } = options;

  return {
    initialState: {
      [resource]: {
        updatingStatus: RequestState.isIdle,
        errors: null,
      },
    },
    reducers: {
      resetResource(state) {
        state[resource].loadingStatus = RequestState.isIdle;
        state[resource].item = null;
        state[resource].errors = null;
      },
    },
    actionCreators(restDispatch) {
      return {
        resetResource: () => {
          restDispatch('resetResource');
        },
      };
    },
    abstractSelector: (state) => ({
      resource: state[resource],
    }),
  };
};

export default resetResourceSlice;
