import { SxStyles } from 'theme';

const styles: SxStyles = {
  prefix: {
    '& span': {
      fontSize: theme => theme.typography.pxToRem(11),
      lineHeight: theme => theme.typography.pxToRem(18),
      fontWeight: 600,
      display: 'inline-block',
      width: theme => theme.spacing(2),
      height: theme => theme.spacing(2),
      background: theme => theme.palette.blackSqueeze,
      marginRight: theme => theme.spacing(1),
      textAlign: 'center',
    },
  },
  breadcrumb: {
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.2s ease-in 0s',
    lineHeight: theme => theme.typography.pxToRem(18),
    '&:hover': {
      color: 'elephant',
    },
  },
};

export default styles;
