import { FieldType } from 'enums/forms';

import { DateHelpers } from 'helpers/DateHelpers';

import { isBlank } from 'utils/predicates';

import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import { equals, pick } from 'ramda';

export const getFormErrorsFromResponse = (axiosError: AxiosError): Record<string, unknown> =>
  axiosError.response?.data?.errors;

export const isCurrentValuesEqualsInitialValues = (
  currentValues: FormikValues,
  initialValues: FormikValues,
): boolean => (initialValues ? equals(currentValues, pick(Object.keys(currentValues), initialValues)) : true);

export const getTextFieldInitialValue = (value: string | number | null): string =>
  isBlank(value) ? '' : String(value);

export const getNumberFieldInitialValue = (value: string | number | null): string =>
  isBlank(value) ? '' : String(Number(value));

export const getYieldFieldInitialValue = (value: string | number | null): string =>
  isBlank(value) ? '0' : String(value);

export const getPercentFieldInitialValue = (value: string | number | null): string =>
  isBlank(value) ? '' : Number(value).toFixed(2);

export const getEditablePercentFieldInitialValue = (value: string | number | null): string =>
  isBlank(value) ? '' : Number(value).toFixed(5);

export const getSelectFieldInitialValue = (value: string | number | null): string =>
  isBlank(value) ? '' : String(value);

export const getDateFieldInitialValue = (value: string | null): Date | null =>
  value ? DateHelpers.toDate(value) : null;

export const getAutocompleteFieldInitialValue = <T>(value: T): T | null => (isBlank(value) ? null : value);

export const getBooleanFieldInitialValue = (value: boolean): boolean => value || false;

export const createField = <T>(fieldParams: Field<T>): Field<T> => ({ ...fieldParams });

export const createIntegerField = <T>({ name, label, placeholder = null, disabled, size = 7 }: InputFieldParams<T>): Field<T> =>
  createField<T>({ name, label, type: FieldType.integer, placeholder, disabled, size });

export const createTextField = <T>({
  name,
  label,
  rows = 1,
  disabled,
  hidden,
  size = 7,
  placeholder = null,
}: InputFieldParams<T>): Field<T> =>
    createField<T>({ name, label, type: FieldType.text, rows, disabled, hidden, size, placeholder });

export const createPercentageField = <T>({
  name,
  label,
  rows = 1,
  disabled,
  hidden,
  placeholder = null,
  size = 7,
}: InputFieldParams<T>): Field<T> =>
    createField<T>({ name, label, type: FieldType.percentage, rows, disabled, hidden, size, placeholder });

export const createEditablePercentageField = <T>({
  name,
  label,
  disabled,
  hidden,
  size = 7,
  onChange,
  allowNegative,
  placeholder = null,
}: InputFieldParams<T>): Field<T> =>
    createField<T>({
      name,
      label,
      type: FieldType.editablePercentage,
      disabled,
      hidden,
      size,
      onChange,
      allowNegative,
      placeholder,
    });

export const createMoneyField = <T>({
  name,
  label,
  disabled,
  hidden,
  size = 7,
  onChange,
  allowNegative,
  placeholder = null,
}: InputFieldParams<T>): Field<T> =>
    createField<T>({
      name,
      label,
      type: FieldType.money,
      disabled,
      hidden,
      size,
      contentRight: true,
      onChange,
      allowNegative,
      placeholder,
    });

export const createFloatField = <T>({
  name,
  label,
  decimalScale = 2,
  placeholder = null,
}: InputFieldParams<T>): Field<T> => createField<T>({ name, label, type: FieldType.float, decimalScale, placeholder });

export const createCheckBoxField = <T>({
  name,
  label,
  onChange,
  hidden,
  placeholder = null,
}: InputFieldParams<T>): Field<T> =>
    createField<T>({ name, label, type: FieldType.checkBox, onChange, hidden, placeholder });

export const createDateField = <T>({
  name,
  label,
  disabled,
  hidden,
  isVisible,
  placeholder = null,
}: InputFieldParams<T>): Field<T> =>
    createField<T>({ name, label, type: FieldType.date, disabled, hidden, isVisible, placeholder });

export const createSelectField = <T>({ name, label, options, disabled, hidden }: SelectFieldParams<T>): Field<T> =>
  createField<T>({ name, label, type: FieldType.select, options, disabled, hidden });

export const createAutocompleteField = <T>(autocompleteFieldParams: AutocompleteFieldParams<T>): Field<T> =>
  createField<T>({ ...autocompleteFieldParams, type: FieldType.autocomplete });

export const createMoneyFieldWithCheckbox = <T>({
  name,
  checkboxName,
  label,
  disabled,
  size = 7,
  onChange,
}: InputFieldParams<T>): Record<string, Field<T>> => ({
    checkbox: createField<T>({ name: checkboxName, label: null, type: FieldType.checkBox, onChange, contentRight: true }),
    field: createField<T>({ name, label, type: FieldType.money, disabled, size, onChange, contentRight: true }),
  });
