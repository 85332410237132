import { useEffect } from 'react';

import { getKeyByValue } from 'utils/keysConverter';
import { isBlank } from 'utils/predicates';

import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { AppBar, Button, Menu, MenuItem, Toolbar } from '@mui/material';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { keys, values } from 'ramda';
import { appRoutes } from 'routes';

import { useUser } from 'hooks/api';
import { useErrorNotification } from 'hooks/useNotification';

import Box from 'components/common/Box';
import Link from 'components/common/Link';
import MainNavigation from 'components/common/MainNavigation';

import styles from './styles';
import SyncUpMenuItem from './SyncUpButton/SyncUpMenuItem';

type HeaderBarProps = {
  handleMenuToggle: (event: never) => void;
  menuAnchor: null | Element | (() => Element);
  handleMenuClose: () => void;
  handleLogOut: () => void;
  isLostConnection: boolean;
  unprocessedError: string;
  isErrorNotificationShown: boolean;
};

const HeaderBar: FC<HeaderBarProps> = (props) => {
  const {
    handleMenuToggle,
    menuAnchor,
    handleMenuClose,
    handleLogOut,
    isLostConnection,
    unprocessedError,
    isErrorNotificationShown,
  } = props;
  
  const { currentUser } = useUser();

  const errorNotification = useErrorNotification();

  const { t } = useTranslation('common');

  useEffect(() => {
    if (isLostConnection) {
      errorNotification(t('lostConnection'));
    }
  }, [isLostConnection]);

  useEffect(() => {
    if (unprocessedError && isErrorNotificationShown) {
      const errorsList = JSON.parse(unprocessedError);
      const errors = values(errorsList);
      const errorsToArrayOfStrings = (error: string[]) => {
        const keysInObject = keys(error);
        const nameOfField = getKeyByValue(errorsList, error).toString().replace(/_/g, ' ');
        const transformName = nameOfField[0].toUpperCase() + nameOfField.slice(1);
        const arrayOfErrorsText = keysInObject.map(
          (key) => t('errorText', {name: transformName, errorField: String(key), errorText: error[key]}),
        );
        const errorText = isBlank(error[0]) ? t('errorPage') : t('errorMessage', {name: transformName, errorText: error});
        return Array.isArray(error) ? errorText : arrayOfErrorsText;
      };
      const unprocessedEntityErrors = errors.map((error) => errorsToArrayOfStrings(error));
      unprocessedEntityErrors.map((error: string) => errorNotification(error));
    }
  }, [unprocessedError, isErrorNotificationShown]);

  return (
    <AppBar position='static' sx={styles.appBar}>
      <Box sx={styles.wrapper}>
        <Toolbar sx={styles.toolBar}>
          <Box sx={styles.logo}>
            <Link href={appRoutes.opportunitiesPath()}>
              <Image src='/icons/logo.svg' alt='Defease With Ease logo' height='24' width='52' />
            </Link>
          </Box>
          <MainNavigation />
          {currentUser && (
            <Box sx={styles.userMenuWrapper}>
              <Button sx={styles.userMenuButton} onClick={handleMenuToggle} data-test-id='currentUserButton'>
                {currentUser.fullName}
                <KeyboardArrowDownIcon sx={styles.userMenuIcon} />
              </Button>
              <Menu
                keepMounted
                sx={styles.userMenu}
                anchorEl={menuAnchor}
                open={!!menuAnchor}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <SyncUpMenuItem />
                <MenuItem sx={styles.userMenuItem} data-test-id='menuItem'>
                  <Link href={appRoutes.accountSettingsPath()}>{t('accountSettings')}</Link>
                </MenuItem>
                <MenuItem sx={styles.userMenuItem} onClick={handleLogOut} data-test-id='menuItem'>
                  {t('logOut')}
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default HeaderBar;
