import { ResourceSlice, SliceOptions } from 'types/slices';

import { RequestState } from 'enums/requestState';

const updateResourceSlice = (options: SliceOptions): ResourceSlice => {
  const { resource, repository } = options;

  return {
    initialState: {
      [resource]: {
        updatingStatus: RequestState.isIdle,
        errors: null,
      },
    },
    reducers: {
      updateResourceStart(state) {
        state[resource].updatingStatus = RequestState.isPending;
        state[resource].errors = null;
      },
      updateResourceSuccess(state) {
        state[resource].updatingStatus = RequestState.isSuccess;
      },
      updateResourceFail(state, { payload: { errors } }) {
        state[resource].updatingStatus = RequestState.isFailure;
        state[resource].errors = errors;
      },
    },
    actionCreators(restDispatch) {
      return {
        updateResource: async (id, params) => {
          try {
            restDispatch('updateResourceStart');
            const data = await repository.update(id, params);
            restDispatch('updateResourceSuccess', data);
          } catch (errors) {
            restDispatch('updateResourceFail', { errors });
          }
        },
      };
    },
    abstractSelector: (state) => ({
      resource: state[resource],
    }),
  };
};

export default updateResourceSlice;
