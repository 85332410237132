import { ComponentsProps } from '@mui/material/styles';

import OutlinedInputProps from './OutlinedInputProps';
import TextFieldProps from './TextFieldProps';

const props: ComponentsProps = {
  ...OutlinedInputProps,
  ...TextFieldProps,
};

export default props;
