import { PromiseFunction } from 'types/slices';

import { DateHelpers } from 'helpers/DateHelpers';

import { AxiosError } from 'axios';
import { IOpportunityLoanLookupIndexWithId } from 'domain/opportunity/loanLookup/types';
import { getLoanLookupInitialValues } from 'domain/opportunity/schemas/update';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useLoanLookupListActions } from 'store/OpportunitySlice';


type UseLoanLookup = {
  autocompleteList: IOpportunityLoanLookupIndexWithId[];
  isListUpdating: boolean;
  hasNextPage: boolean;
  loadList: PromiseFunction<void>;
  isListPending: boolean;
  resetList: () => void;
  setMoodysDate: () => void;
  clearMoodysDate: () => void;
  pulledFromMoodysDate: string;
  loanLookupListLoadError: AxiosError;
};

const useLoanLookup = (): UseLoanLookup => {
  const {
    loanLookupList,
    isLoanLookupListLoading,
    resetLoanLookupList,
    pulledFromMoodysDate,
    loanLookupListLoadError,
  } = useSelector((state: RootState) => state.OpportunitySlice);

  const { loadLoanLookupList, setMoodysDate, clearMoodysDate } = useLoanLookupListActions();

  const hasNextPageLoanLookupList = !!loanLookupList?.next;

  const autocompleteList = loanLookupList.map((loanLookup) => getLoanLookupInitialValues(loanLookup));

  return {
    autocompleteList,
    isListUpdating: false,
    isListPending: isLoanLookupListLoading,
    loadList: loadLoanLookupList,
    hasNextPage: hasNextPageLoanLookupList,
    resetList: resetLoanLookupList,
    setMoodysDate,
    clearMoodysDate,
    pulledFromMoodysDate: DateHelpers.toIso(pulledFromMoodysDate),
    loanLookupListLoadError,
  };
};

export default useLoanLookup;
