/* eslint-disable react-hooks/rules-of-hooks, import/namespace */

import { Reducers, RestSlice, SliceConfig } from 'types/slices';

import { keys, pick } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import { keysToRealName, toRealName, toSliceName } from './conventions';
import * as availableSlices from './slices';
import { combineSlicesBy, populateOptions, validateConfig } from './utils';

const restDispatch =
  (dispatch, reduxActions, config) =>
    (actionName, params = {}) => {
      const action = reduxActions.actions[toRealName(actionName, config.resource)];
      if (action) {
        dispatch(action(params));
      }
    };

export const createRestSlice = (config: SliceConfig): RestSlice => {
  validateConfig(config, availableSlices);
  const options = populateOptions(config);

  const enablesSlices = config.slices.map((s) => {
    const sliceCreator = availableSlices[toSliceName(s, config.resource)];

    if (!sliceCreator) {
      throw new Error(`${s} is not valid REST slice.`);
    }

    return sliceCreator(options);
  });

  const initialState = combineSlicesBy('initialState')(enablesSlices);

  const reducers = combineSlicesBy<Reducers>('reducers', keysToRealName(config.resource))(enablesSlices);

  const useHook = (reduxActions, storeName) => () => {
    const dispatch = useDispatch();
    const state = useSelector((s) => pick(keys(initialState), s[storeName]));

    const actions = combineSlicesBy('actionCreators', (creator) =>
      keysToRealName(config.resource)(creator(restDispatch(dispatch, reduxActions, config))),
    )(enablesSlices);

    return {
      ...state,
      ...actions,
    };
  };

  const useAbstractHook = (reduxActions, storeName) => () => {
    const dispatch = useDispatch();

    const state = useSelector((s) =>
      combineSlicesBy('abstractSelector', (selector) => selector(s[storeName]))(enablesSlices),
    );

    const actions = combineSlicesBy('actionCreators', (creator) =>
      creator(restDispatch(dispatch, reduxActions, config)),
    )(enablesSlices);

    return {
      ...state,
      ...actions,
    };
  };

  return {
    initialState,
    reducers,
    hooks: (reducerActions, storeName) => ({
      useAbstract: useAbstractHook(reducerActions, storeName),
      use: useHook(reducerActions, storeName),
    }),
  };
};
