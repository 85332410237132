import { IResourceResponse } from 'types/api';

import { CacheTag } from 'enums/cacheTag';

import { selectDataFromResponse } from 'utils/responseData';

import { apiRoutes } from 'routes';
import { apiContainer } from 'store';

import { AccountCreationFormSerializedData } from './schema';
import { IAccountItem, IAccountIndex } from './types';

export const accountsApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    getAccounts: builder.query<IResourceResponse<Array<IAccountIndex>>, QueryParams>({
      query: params => (
        {
          url: apiRoutes.accountsPath(),
          method: 'GET',
          params,
        }
      ),
      providesTags: (result) =>
        result
          ? [...result.response.map(({ id }) => ({ type: CacheTag.accounts as const, id })), { type: CacheTag.accounts, id: 'LIST' }, CacheTag.accounts]
          : [CacheTag.accounts],
    }),
    getAccount: builder.query<IResourceResponse<IAccountItem>, number>({
      query: (id) => ({
        url: apiRoutes.accountPath(id),
        method: 'GET',
      }),
      providesTags: (result) => result ? [{ type: CacheTag.accounts, id: result.response.id }] : [CacheTag.accounts],
    }),
    createAccount: builder.mutation<IAccountItem, AccountCreationFormSerializedData>({
      query: ({ ...data }) => ({
        url: apiRoutes.accountsPath(),
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: CacheTag.accounts, id: 'LIST' }],
      transformResponse: selectDataFromResponse,
    }),
    updateAccount: builder.mutation<IResourceResponse<IAccountItem>, QueryParams>({
      query: ({ id, account }) => ({
        url: apiRoutes.accountPath(id),
        method: 'PATCH',
        data: account,
      }),
      invalidatesTags: (result) => result ? [{ type: CacheTag.accounts, id: result.response.id }] : [CacheTag.accounts],
    }),
    deleteAccount: builder.mutation<void, number>({
      query: (id) => ({
        url: apiRoutes.accountPath(id),
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: CacheTag.accounts, id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const { 
  useLazyGetAccountsQuery,
  useLazyGetAccountQuery,
  useCreateAccountMutation,
  useUpdateAccountMutation,
  useDeleteAccountMutation,
} = accountsApi;
