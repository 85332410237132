import { IDataListServerResponse, IResponseData } from 'types/api';

import { DateHelpers } from 'helpers/DateHelpers';
import { FetchHelper } from 'helpers/FetchHelper';
import { UrlHelper } from 'helpers/UrlHelper';

import { OpportunityFormData } from 'domain/opportunity/schemas/update';
import { apiRoutes } from 'routes';

import { createRepository } from './createRepository';

export const opportunityRepository = {
  ...createRepository(apiRoutes.opportunitiesPath()),
  async yieldMaintenanceLoad<P>(opportunityId: number): Promise<IResponseData<P>> {
    return FetchHelper.post(apiRoutes.opportunitiesYieldMaintenancePath(opportunityId));
  },
  async loanLookupListLoad<P>(queryParams: QueryParams): Promise<IDataListServerResponse<P>> {
    return FetchHelper.get(apiRoutes.opportunitiesExternalSearchPath(UrlHelper.stringifyParams(queryParams)));
  },
  async clone<T>(opportunityId: number): Promise<T> {
    return FetchHelper.post(apiRoutes.opportunityClonePath(), { opportunityId });
  },
  async multipleUpdate<T>(estimatedCloseDate: string, queryParams: QueryParams): Promise<T> {
    return FetchHelper.patch(`${apiRoutes.opportunitiesPath()}?${UrlHelper.stringifyParams(queryParams)}`, {
      estimatedCloseDate,
    });
  },
};

export const opportunitiesTransactionCostQueryParams = (opportunity: OpportunityFormData): QueryParams => {
  const loan = opportunity?.loan;
  return {
    loanAmount: loan?.originalLoanBalance,
    estimatedCloseDate: DateHelpers.toIso(opportunity?.estimatedCloseDate),
    maturityDate: DateHelpers.toIso(loan?.maturityDate),
    poolId: loan?.pool?.id,
    servicerId: loan.isSubRunsDeal ? loan?.subServicer?.id : loan?.servicer?.id,
    successorBorrowerId: loan?.successorBorrowerCompany?.id,
    isCanadian: loan?.isCanadian,
  };
};

