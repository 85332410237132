import { useEffect } from 'react';

import { useRouter } from 'next/router';

export const useRouteChangePrevent = (
  onRouteChangePrevented: (URL: string) => void,
  shouldPreventRouteChange: boolean,
  errorText: string,
): void => {
  const router = useRouter();

  useEffect(() => {
    const routeChangeStart = (URL: string) => {
      if (shouldPreventRouteChange) {
        onRouteChangePrevented(URL);

        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw errorText;
      }
    };
    router.events.on('routeChangeStart', routeChangeStart);

    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
    };
  }, [onRouteChangePrevented, router.asPath, router.events, shouldPreventRouteChange]);
};
