import { deleteToken } from 'repositories/tokens';

import { AxiosError } from 'axios';
import { ICurrentUser } from 'domain/user/types';
import { NextRouter } from 'next/router';
import { head, isEmpty, isNil } from 'ramda';
import { appRoutes } from 'routes';

import { CookieHelper } from './CookieHelper';

const NON_AUTH_PAGES = [appRoutes.loginPath(), appRoutes.passwordForgotPath(), appRoutes.passwordResetPath()];

export const AuthHelper = {
  isAuthorized: (currentUser: ICurrentUser, accessToken: string): boolean =>
    Boolean(!isEmpty(currentUser) && accessToken),
  doesCurrentPageNeedAuth: (currentPageUrl: string): boolean => NON_AUTH_PAGES.includes(currentPageUrl),
  setErrorsFromResponse: (error: AxiosError, setFieldErrors: (errors) => void, setFormError: (errors) => void): void => {
    if (!isNil(error) && error.response?.data?.errors) {
      setFieldErrors(error.response?.data?.errors);
      const { nonFieldErrors } = error.response.data.errors;
      setFormError(nonFieldErrors ? head(nonFieldErrors) : null);
    }
  },
  isRequestCorrect: (status: string): boolean => status === 'OK',
  logout: (resetCurrentUser: () => void, router: NextRouter): void => {
    CookieHelper.destroyAccessToken();
    deleteToken();
    resetCurrentUser();
    router.push(appRoutes.loginPath());
  },
};
