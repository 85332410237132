import { Shadows } from '@mui/material/styles/shadows';

const shadowsOverride: Shadows = [
  'none',
  '0px 0px 1px 0px rgba(19, 51, 76, .31)',
  '0px 15px 24px 0px rgba(19, 51, 76, .1)',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
];

export default shadowsOverride;
