import { IResourceResponse } from 'types/api';

import { pathOr } from 'ramda';

export const selectDataFromResponse = (response: IResourceResponse<unknown>) => 
  pathOr(null, ['response'], response);

export const selectIdFromResponse = (response: IResourceResponse<unknown>) => 
  pathOr(null, ['response', 'id'], response);

