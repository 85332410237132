import palette from './palette';

export default  {
  htmlFontSize: 14,
  fontSize: 14,
  body1: {
    fontSize: 14,
    color: palette.text.primary,
  },
};
