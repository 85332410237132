import { axiosBaseQuery } from 'utils/axiosBaseQuery';

import { configureStore } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { createWrapper } from 'next-redux-wrapper';
import rootReducer from 'store/rootReducer';

export const apiContainer = createApi({
  reducerPath: 'api',
  tagTypes: [
    'currentUser',
    'users',
    'options',
    'token',
    'credIQ',
    'yardi',
    'successorBorrowers',
    'multipacks',
    'accounts',
    'contacts',
    'pools',
    'opportunities',
    'managers',
    'servicers',
    'opportunityContact',
    'pricingDetail',
    'document',
    'credIQFinancial',
    'lifeOfLoan',
    'moodysYardiLoanLookup',
    'amortizationSchedule',
    'transactionCost',
    'securitiesCost',
    'successorBorrowerCompanies',
    'loanLookup',
    'sharepoint',
    'yardiExport',
    'salesForceSyncUp',
    'email',
    'opbDates'
  ],
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
});

const { reducerPath, reducer, middleware } = apiContainer;

let store;

export const makeStore = () => {
  store = configureStore({
    reducer: {
      [reducerPath]: reducer,
      ...rootReducer,
    },
    middleware: getDefaultMiddleware => [...getDefaultMiddleware(), middleware],
  });
  return store;
};

export const wrapper = createWrapper(makeStore);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

  