import { DateHelpers } from 'helpers/DateHelpers';

import { getBooleanFieldInitialValue, getDateFieldInitialValue, getEditablePercentFieldInitialValue, getSelectFieldInitialValue, getTextFieldInitialValue } from 'utils/formik';

import { OpportunityPricingDetail } from 'domain/opportunity/pricingDetail/types';
import { OpportunityFormData, serializeMoneyField } from 'domain/opportunity/schemas/update';
import { IOptions } from 'domain/options/types';
import { isEmpty , head } from 'ramda';

import { IPricingDetail } from './types';

export const serializeFields = (
  values: OpportunityFormData,
  fields: OpportunityPricingDetail,
  pricingDetailActiveIndex: number | null = null,
  pricingDetails: OpportunityPricingDetail,
): IPricingDetail => ({
  ...fields,
  mostRecentUpdatePrice: fields.mostRecentUpdatePrice || undefined,
  restrictedPrice: fields.restrictedPrice || undefined,
  newPrice: fields.newPrice || undefined,
  balloonPayment: fields.balloonPayment || undefined,
  calculatedBalloonPayment: serializeMoneyField(
    pricingDetails?.[pricingDetailActiveIndex]?.calculatedBalloonPayment || fields.calculatedBalloonPayment,
  ),
  closingDate: DateHelpers.toIso(
    pricingDetails?.[pricingDetailActiveIndex]?.closingDate || values.estimatedCloseDate,
  ),
  munexFloatRate: fields.munexFloatRate || undefined,
  newYield: Number(fields.newYield).toFixed(5),
  recentUpdateYield: Number(fields.recentUpdateYield).toFixed(5),
  standardPrice: fields.standardPrice || undefined,
  startMakingPaymentsOn: isEmpty(fields.startMakingPaymentsOn)
    ? undefined
    : DateHelpers.toIso(fields.startMakingPaymentsOn),
  opportunityId: values.id,
});

export const getPricingDetailInitialValues = (
  pricingDetail: IPricingDetail,
  options: IOptions,
  opportunityId: number,
  lastPricingDetail?: IPricingDetail,
): OpportunityPricingDetail => ({
  ...pricingDetail,
  closingDate: getDateFieldInitialValue(pricingDetail?.closingDate || null),
  newPrice: getTextFieldInitialValue(pricingDetail?.newPrice || null),
  updatedAt: getTextFieldInitialValue(pricingDetail?.updatedAt || null),
  newYield: getEditablePercentFieldInitialValue(pricingDetail?.newYield || null),
  bps1Amount: getTextFieldInitialValue(pricingDetail?.bps1Amount || null),
  applyTo: getSelectFieldInitialValue(
    pricingDetail?.applyTo || lastPricingDetail?.applyTo || head(Object.values(options.pricingDetailApplyTo)).value,
  ),
  balloonPayment: getTextFieldInitialValue(pricingDetail?.balloonPayment || null),
  businessDay: getSelectFieldInitialValue(
    pricingDetail?.businessDay ||
        lastPricingDetail?.businessDay ||
        head(Object.values(options.pricingDetailBusinessDays)).value,
  ),
  calculatedBalloonPayment: getTextFieldInitialValue(pricingDetail?.calculatedBalloonPayment || null),
  isCalculateSensitivity: getBooleanFieldInitialValue(pricingDetail?.isCalculateSensitivity || null),
  isOverrideBalloonPayment: getBooleanFieldInitialValue(pricingDetail?.isOverrideBalloonPayment || lastPricingDetail?.isOverrideBalloonPayment || null),
  munexBalloonPayment: getTextFieldInitialValue(pricingDetail?.munexBalloonPayment || null),
  munexFloatRate: getTextFieldInitialValue(pricingDetail?.munexFloatRate || null),
  restrictSecurities: getSelectFieldInitialValue(
    pricingDetail?.restrictSecurities ||
        lastPricingDetail?.restrictSecurities ||
        head(Object.values(options.pricingDetailRestrictSecurities)).value,
  ),
  restrictedPrice: getTextFieldInitialValue(pricingDetail?.restrictedPrice || null),
  securityMaturity: getSelectFieldInitialValue(
    pricingDetail?.securityMaturity ||
        lastPricingDetail?.securityMaturity ||
        head(Object.values(options.pricingDetailSecurityMaturities)).value,
  ),
  standardPrice: getTextFieldInitialValue(pricingDetail?.standardPrice || null),
  startMakingPaymentsOn: getDateFieldInitialValue(pricingDetail?.startMakingPaymentsOn || null),
  opportunityId,
});
  