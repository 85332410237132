const FormControlLabelOverrides: OverrideWithTheme = (theme) => ({
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  },
});

export default FormControlLabelOverrides;
