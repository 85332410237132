import createResourceSlice from './createResourceSlice';
import deleteResourceSlice from './deleteResourceSlice';
import loadResourceListSlice from './loadResourceListSlice';
import loadResourceSlice from './loadResourceSlice';
import resetResourceListSlice from './resetResourceListSlice';
import resetResourceSlice from './resetResourceSlice';
import updateResourceSlice from './updateResourceSlice';

export {
  loadResourceSlice,
  loadResourceListSlice,
  createResourceSlice,
  updateResourceSlice,
  deleteResourceSlice,
  resetResourceSlice,
  resetResourceListSlice,
};
