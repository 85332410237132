import { TaskPath } from 'enums/taskPath';

import { makeRoutes } from 'utils/routes';

const apiRoutes = makeRoutes({
  rootPath: () => '/',
  usersCurrentPath: () => '/users/current/',
  usersPath: () => '/users/',
  accountsPath: () => '/accounts/',
  accountPath: (id) => `/accounts/${id}/`,
  contactsPath: () => '/contacts/',
  contactPath: (id) => `/contacts/${id}/`,
  tokenPath: () => '/token/',
  tokenRefreshPath: () => '/token/refresh/',
  opportunitiesPath: () => '/opportunities/',
  opportunityPath: (id) => `/opportunities/${id}/`,
  opportunitiesYieldMaintenancePath: (id) => `/opportunities/${id}/yield-maintenance/`,
  opportunitiesExternalSearchPath: (params) => `/opportunities/external-search/?${params}`,
  multipacksCalculatePath: (multipackId: number) => `/multipacks/${multipackId}/calculate/`,
  multipackCalculatePath: (id) => `/tasks/multipack-calculate/${id}`,
  opportunityContactsPath: () => '/opportunity-contacts/',
  opportunityContactPath: (id) => `/opportunity-contacts/${id}/`,
  pricingDetailsPath: () => '/pricing-details/',
  pricingDetailPath: (id) => `/pricing-details/${id}/`,
  opportunityClonePath: () => '/opportunities/clone/',
  optionsPath: () => '/options/',
  poolsPath: () => '/pools/',
  poolPath: (id) => `/pools/${id}/`,
  servicersPath: () => '/servicers/',
  multipacksPath: () => '/multipacks/',
  multipackPath: (id) => `/multipacks/${id}/`,
  managersPath: () => '/managers/',
  successorBorrowersPath: () => '/successor-borrowers/',
  successorBorrowerPath: (id) => `/successor-borrowers/${id}/`,
  successorBorrowerCompaniesPath: () => '/successor-borrower-companies/',
  tasksSecuritiesCostsPath: () => '/tasks/securities-costs/',
  tasksSecuritiesCostPath: (id) => `/tasks/securities-costs/${id}`,
  tasksAmortizationSchedulesPath: () => '/tasks/amortization-schedules/',
  tasksAmortizationSchedulePath: (id) => `/tasks/amortization-schedules/${id}/`,
  tasksTransactionCostsPath: () => '/tasks/transaction-cost/',
  tasksTransactionCostPath: (id) => `/tasks/transaction-cost/${id}`,
  opbDatesPath: () => '/opb-dates/',
  opportunityLifeOfLoanDocument: (opportunityId: number) =>
    `/opportunities/${opportunityId}/life-of-loan-documents/`,
  opportunityEngagementLetterDocument: (opportunityId: number) =>
    `/opportunities/${opportunityId}/engagement-letter-documents/`,
  opportunityEngagementInDocument: (opportunityId: number) =>
    `/opportunities/${opportunityId}/engagement-in-documents/`,
  opportunityDraftEmailTasks: (opportunityId: number) => `/opportunities/${opportunityId}/draft-email-tasks/`,
  opportunityDraftClosingExcelDocument: (opportunityId: number) =>
    `/opportunities/${opportunityId}/draft-closing-excel-documents/`,
  opportunityDraftClosingPdfDocument: (opportunityId: number) =>
    `/opportunities/${opportunityId}/draft-closing-pdf-documents/`,
  opportunityFinalClosingExcelDocument: (opportunityId: number) =>
    `/opportunities/${opportunityId}/final-closing-excel-documents/`,
  opportunityFinalClosingPdfDocument: (opportunityId: number) =>
    `/opportunities/${opportunityId}/final-closing-pdf-documents/`,
  opportunityWorkingPartyListDocument: (opportunityId: number) =>
    `/opportunities/${opportunityId}/working-party-list-documents/`,
  opportunityAuthorizationFormDocument: (opportunityId: number) =>
    `/opportunities/${opportunityId}/authorization-form-documents/`,
  opportunityQuoteLetterDocument: (opportunityId: number) => `/opportunities/${opportunityId}/quote-letter-documents/`,
  opportunityDefeasanceClosingSummaryDocument: (opportunityId: number) => `/opportunities/${opportunityId}/defeasance-closing-summary-documents/`,
  opportunityAmortizationScheduleDocumentsTasks: (opportunityId: number) =>
    `/opportunities/${opportunityId}/amortization-schedule-documents-tasks/`,
  opportunityTCFDebtModelingOptimizationTasks: (opportunityId: number) => `/opportunities/${opportunityId}/tcf-debt-modeling-optimization-tasks/`,
  opportunityTCFDebtModelingScenarioTasks: (opportunityId: number) => `/opportunities/${opportunityId}/tcf-debt-modeling-scenario-tasks/`,
  populateFromMoodys: () => '/populate-from-crediq/',
  tasksOpportunityDocumentStatusPath: (id) => `/tasks/opportunity-documents/${id}/`,
  tasksOpportunityLifeOfLoanDocumentStatusPath: (id) => `/tasks/life-of-loan-documents/${id}/`,
  tasksMoodysYardiLoanLookupDocumentPath: () =>  '/tasks/moodys-yardi-loan-lookup/',
  tasksMoodysYardiLoanLookupResultDocumentPath: (id) =>  `/tasks/moodys-yardi-loan-lookup/${id}/`,
  salesForceSyncPath: () => '/salesforce-sync/',
  currentUserPasswordPath: () => '/current-user/password/',
  passwordResetPath: () => '/password/reset/',
  passwordResetConfirmPath: () => '/password/reset/confirm/',
  passwordResetValidateTokenPath: () => '/password/reset/validate_token/',
  opportunitiesSharepointFolderTasksPath: (opportunityId) =>
    `/opportunities/${opportunityId}/${TaskPath.sharepointFolder}/`,
  opportunitiesSharepointFileTasksPath: (opportunityId) =>
    `/opportunities/${opportunityId}/${TaskPath.sharepointFile}/`,
  opportunitiesSharepointFolderTaskPath: (opportunityId, taskId) =>
    `/opportunities/${opportunityId}/${TaskPath.sharepointFolder}/${taskId}/`,
  opportunitiesSharepointFileTaskPath: (opportunityId, taskId) =>
    `/opportunities/${opportunityId}/${TaskPath.sharepointFile}/${taskId}/`,
  opportunityDraftClosingLetterEmailTasks: (opportunityId) =>
    `/opportunities/${opportunityId}/${TaskPath.draftClosingLetterEmail}/`,
  opportunityEngagementLetterEmailTasks: (opportunityId) =>
    `/opportunities/${opportunityId}/${TaskPath.engagementLetterEmail}/`,
  opportunityEngagementInEmailTasks: (opportunityId) =>
    `/opportunities/${opportunityId}/${TaskPath.engagementInEmail}/`,
  opportunityFinalClosingLetterEmailTasks: (opportunityId) =>
    `/opportunities/${opportunityId}/${TaskPath.finalClosingLetterEmail}/`,
  opportunityDefeasanceClosingLetterEmailTasks: (opportunityId) =>
    `/opportunities/${opportunityId}/${TaskPath.defeasanceClosingLetterEmail}/`,
  opportunityQuoteLetterEmailTasks: (opportunityId) => `/opportunities/${opportunityId}/${TaskPath.quoteLetterEmail}/`,
  opportunityWorkingPartyListEmailTasks: (opportunityId) =>
    `/opportunities/${opportunityId}/${TaskPath.workingPartyListEmail}/`,
  opportunityAuthorizationFormEmailTasks: (opportunityId) =>
    `/opportunities/${opportunityId}/${TaskPath.authorizationFormEmail}/`,
  opportunityDefeasanceClosingSummaryEmailTasks: (opportunityId) =>
    `/opportunities/${opportunityId}/${TaskPath.defeasanceClosingSummaryEmail}/`,
  tasksEWSEmails: (id) => `/tasks/ews-emails/${id}/`,
  tasksYardisUploadPath: () => '/tasks/yardi-upload/',
  tasksYardiUploadPath: (id) => `/tasks/yardi-upload/${id}/`,
  tasksYardisExportPath: () => '/tasks/yardi-export/',
  tasksYardiExportPath: (id) => `/tasks/yardi-export/${id}/`,
  tasksYardiExportAllPath: () => '/tasks/yardi-export-all/',
  yardiSearchPath: () => '/yardi-search/',
  credIQSearchPath: () => '/crediq-search/',
});

export default apiRoutes;
