import React, { Fragment, ReactElement, useEffect, useState } from 'react';

import { AppProps } from 'types/global';

import { SessionStorageKeys } from 'enums/sessionStorageKeys';

import BaseLayout from 'layouts/Base';

import { SessionStorage } from 'helpers/SessionStorage';

import createEmotionCache from 'utils/createEmotionCache';

import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { SnackbarProvider } from 'notistack';
import { isNil } from 'ramda';
import { Provider } from 'react-redux';
import { wrapper } from 'store';
import theme from 'theme';

import AccessTokenProvider from 'providers/AccessTokenProvider';
import BreadcrumbsProvider from 'providers/BreadcrumbsProvider';
import ConfirmProvider from 'providers/ConfirmProvider';

const clientSideEmotionCache = createEmotionCache();

const App = (appProps: AppProps): ReactElement => {
  const { Component, ...rest } = appProps;
  const { store, props } = wrapper.useWrappedStore(rest);
  const { emotionCache = clientSideEmotionCache, pageProps } = props;
  const [isLostConnectionError, setLostConnectionError] = useState<boolean>(false);
  const [unprocessedError, setUnprocessedError] = useState<string | null>(null);
  const [isErrorNotificationShown, setErrorNotificationShown] = useState<boolean>(true);
  const Layout = Component.Layout === null ? Fragment : Component.Layout || BaseLayout;
  const { layoutProps } = Component || {};

  setChonkyDefaults({ iconComponent: ChonkyIconFA });

  useEffect(() => {
    if (!isNil(window)) {
      window.onstorage = () => {
        const isLostConnection = SessionStorage.isLostConnectionInLocalStorage();
        if (isLostConnection) setLostConnectionError(isLostConnection);
        const isUnprocessedEntity = SessionStorage.get(SessionStorageKeys.unprocessedEntityError);
        setUnprocessedError(isUnprocessedEntity);
      };
    }
    return () => {
      window.onstorage = null;
    };
  }, []);
 
  return (
    <Provider store={store}>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>Defease With Ease</title>
          <meta name='viewport' content='initial-scale=1, width=device-width' />
          <link rel='icon' href='/favicon.ico' />
          <link rel='preconnect' href='https://fonts.googleapis.com' />
          <link rel='preconnect' href='https://fonts.gstatic.com' />
        </Head>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SnackbarProvider>
                <ConfirmProvider>
                  <CssBaseline />
                  <BreadcrumbsProvider>
                    <AccessTokenProvider>
                      <Layout
                        isErrorNotificationShown={isErrorNotificationShown}
                        isLostConnectionError={isLostConnectionError}
                        unprocessedError={unprocessedError}
                        {...layoutProps}
                      >
                        <Component
                          setErrorNotificationShown={setErrorNotificationShown}
                          unprocessedError={unprocessedError}
                          {...pageProps}
                        />
                      </Layout>
                    </AccessTokenProvider>
                  </BreadcrumbsProvider>
                </ConfirmProvider>
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </CacheProvider>
    </Provider>
  );
};

export default appWithTranslation(App);
