import palette from 'theme/palette';

const ButtonOverrides: OverrideWithTheme = (theme) => ({
  MuiButton: {
    styleOverrides: {
      root: {
        height: 32,
        textTransform: 'none',
        padding: '6px 24px',
        transition: theme.transitions.create(['background-color', 'box-shadow', 'border', 'color'], {
          duration: theme.transitions.duration.short,
        }),
      },
      sizeSmall: {
        whiteSpace: 'nowrap',
      },
      contained: {
        boxShadow: 'none',
        backgroundColor: 'rgba(19, 51, 76, 0.05)',
        color: 'rgba(19, 51, 76, 0.75)',

        '&:hover': {
          backgroundColor: 'rgba(19, 51, 76, 0.08)',
        },

        '&.Mui-disabled': {
          backgroundColor: 'rgba(19, 51, 76, 0.05)',
          color: 'rgba(19, 51, 76, 0.3)',
        },
      },
      containedPrimary: {
        color: palette.white,
        backgroundColor: theme.palette.primary.main,

        '&:hover': {
          backgroundColor: 'rgb(0, 114, 156)',
        },
      },
      containedError: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.white,

        '&:hover': {
          backgroundColor: '#C9382E',
        },
      },
      textPrimary: {
        color: theme.palette.text.primary,
      },
      containedSizeSmall: {
        fontSize: theme.typography.pxToRem(14),
        padding: '2px 11px',
        minWidth: 0,
        height: 32,
      },
    },
  },
});

export default ButtonOverrides;
