import { SessionStorageKeys } from 'enums/sessionStorageKeys';

export const SessionStorage = {
  get(name: string): string {
    return sessionStorage.getItem(name);
  },
  set(name: string, value: string): void {
    sessionStorage.setItem(name, value);
    dispatchEvent(new Event('storage'));
  },
  remove(name: string): void {
    sessionStorage.removeItem(name);
    dispatchEvent(new Event('storage'));
  },
  setIsLostConnectionInLocalStorage(): void {
    this.set(SessionStorageKeys.isLostConnect, 'true');
  },
  removeIsLostConnectionFromLocalStorage(): void {
    this.remove(SessionStorageKeys.isLostConnect);
  },
  isLostConnectionInLocalStorage(): boolean {
    return !!this.get(SessionStorageKeys.isLostConnect);
  },
  setTabIdInSessionStorage(value: string): void {
    this.set(SessionStorageKeys.tabId, value);
  },
  getTabIdFromSessionStorage(): string {
    return this.get(SessionStorageKeys.tabId);
  },
  removeTabIdFromSessionStorage(): string {
    return this.remove(SessionStorageKeys.tabId);
  },
};
