import palette from 'theme/palette';

const TableOverrides: OverrideWithTheme = () => ({
  MuiTable: {
    styleOverrides: {
      root: {
        backgroundColor: palette.white,
        borderWidth: 1,
        borderColor: palette.cornflowerBlue,
        borderStyle: 'solid',
        tableLayout: 'fixed',
      },
    },
  },
});

export default TableOverrides;
