import { createContext, useEffect } from 'react';

import redirectRules from 'routes/redirectRules';

import { AuthHelper } from 'helpers/AuthHelper';
import { CookieHelper } from 'helpers/CookieHelper';
import { initializeAxiosInterceptors } from 'helpers/FetchHelper';

import { initializeRedirects } from 'utils/redirects';
import { isUnprocessedEntityError } from 'utils/responseErrors';

import { useLazyGetOptionsQuery } from 'domain/options/apiSlice';
import { useRouter } from 'next/router';
import { isNil } from 'ramda';
import { appRoutes } from 'routes';

import { setUpRollbar } from 'config/rollbar';

import { useUser } from 'hooks/api';

const AccessTokenContext = createContext(null);

const AccessTokenProvider: FC = (props) => {
  const { children } = props;
  const { getCurrentUser, currentUser, isCurrentUserLoading, userError } = useUser();
  const [ getOptions, { data: options } ] = useLazyGetOptionsQuery();

  const router = useRouter();

  const accessToken = CookieHelper.getAccessToken();

  useEffect(() => {
    setUpRollbar();
    initializeAxiosInterceptors(router);
    initializeRedirects(router, redirectRules);
  }, [router]);

  const updateUserAndOptions = async () => {
    await getCurrentUser();
    await getOptions();
  };

  useEffect(() => {
    if (accessToken) {
      if (router.pathname !== appRoutes.loginPath()) {
        updateUserAndOptions();
      }
    } else if (!AuthHelper.doesCurrentPageNeedAuth(router.pathname)) {
      router.push(appRoutes.loginPath());
    }
  }, [accessToken]);

  useEffect(() => {
    if (userError && isUnprocessedEntityError(userError)) {
      router.push(appRoutes.loginPath());
    }
  }, [userError]);

  const isAuthorized = AuthHelper.isAuthorized(currentUser, accessToken);

  useEffect(() => {
    const comparableAppRoutes = [appRoutes.loginPath(), appRoutes.rootPath(), appRoutes.passwordForgotPath()];
    const isLogInOrRootPath = comparableAppRoutes.some((appRoute) => appRoute === router.pathname);
    const shouldRedirectToOpportunities = isAuthorized && isLogInOrRootPath;
    if (shouldRedirectToOpportunities) {
      router.push(appRoutes.opportunitiesPath()).then(router.reload);
    }
  }, [currentUser]);

  const isUserAuthorizedButOptionsWasNotLoaded = isAuthorized && !options;
  const isOnLoginPageWithoutLoadedOptions =
    !options && !AuthHelper.doesCurrentPageNeedAuth(router.route);
  const isCurrentUserNotLoaded = isNil(currentUser) && isNil(isCurrentUserLoading);
  const isRedirectsNotLoaded = router.asPath === appRoutes.accountSettingsPath();

  if (isCurrentUserNotLoaded || isUserAuthorizedButOptionsWasNotLoaded || isOnLoginPageWithoutLoadedOptions || isRedirectsNotLoaded) {
    return null;
  }

  return (
    <AccessTokenContext.Provider value={accessToken}>{children}</AccessTokenContext.Provider>
  );
};

export default AccessTokenProvider;
