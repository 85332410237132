const TypographyOverrides: OverrideWithTheme = () => ({
  MuiTypography: {
    styleOverrides: {
      root: {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
      },
    },
  },
});

export default TypographyOverrides;
