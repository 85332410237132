import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    minHeight: '100%',
    background: theme => `linear-gradient(to bottom, ${theme.palette.catskillWhite} 40%, ${theme.palette.background.default} 40%);`,
  },
  appBar: {
    backgroundColor: 'text.primary',
    height: theme => theme.layouts.baseLayout.header.height,
  },
  wrapper: {
    paddingLeft: 2,
    paddingRight: 2,
  },
  toolBar: {
    minHeight: 48,
    position: 'relative',
  },
  title: {
    fontSize: theme => theme.typography.pxToRem(29),
  },
  logo: {
    position: 'absolute',
  },
  userMenuWrapper: {
    maxWidth: 450,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  userMenuButton: {
    color: 'white',
    opacity: 0.7,
    whiteSpace: 'nowrap',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  userMenuIcon: {
    width: 18,
    height: 18,
    marginLeft: theme => theme.spacing(0.5),
  },
  userMenu: {
    marginTop: theme => theme.spacing(2),
    marginLeft: -2,
  },
  userMenuItem: {
    minHeight: 0,
    minWidth: 150,
    position: 'relative',

    '& a': {
      width: '100%',
    },
  },
};

export default styles;
