export enum HttpStatusCode {
  movedPermanently = 301,
  permanentRedirect = 308,
  badRequest = 400,
  unauthorized = 401,
  notFound = 404,
  unprocessedEntity = 422,
  internalServer = 500,
  badGateway = 502,
}
