const TableCellOverrides: OverrideWithTheme = (theme) => ({
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderColor: theme.palette.cornflowerBlue,
        paddingTop: theme.spacing(1.75),
        paddingBottom: theme.spacing(1.75),
        width: '100%',
        overflow: 'hidden',
        webkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        '&:first-child': {
          paddingLeft: theme.spacing(4),
        },

        '&.deleteCell': {
          width: 0,
          paddingLeft: 0,
          paddingRight: 40,
        },
      },
      head: {
        color: theme.palette.santasGray,
        fontWeight: 'bold',
        fontSize: theme.typography.pxToRem(10),
        textTransform: 'uppercase',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textOverflow: 'clip',
      },
    },
  },
});

export default TableCellOverrides;
