import { appRoutes } from 'routes';

export type RedirectRule = {
  from: string;
  to: string;
};

export type RedirectRules = RedirectRule[];

const redirectRules: RedirectRules = [
  { from: appRoutes.resourcesPath(), to: appRoutes.resourcesPoolsPath() },
  { from: appRoutes.accountSettingsPath(), to: appRoutes.accountSettingsChangePasswordPath() },
  { from: appRoutes.passwordPath(), to: appRoutes.passwordForgotPath() },
];

export default redirectRules;
