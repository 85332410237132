import { useCallback, useState } from 'react';

import { AuthHelper } from 'helpers/AuthHelper';

import { Box, Backdrop, CircularProgress } from '@mui/material';
import { useRouter } from 'next/router';

import { useUser } from 'hooks/api';
import { useBreadcrumbsContext } from 'hooks/useBreadcrumbsContext';

import Breadcrumbs from 'components/common/Breadcrumbs';
import HeaderBar from 'components/common/HeaderBar';

import styles from './styles';

type Type = string | number;

interface BaseProps {
  title?: Type;
  isLostConnectionError: boolean;
  unprocessedError: string;
  isErrorNotificationShown: boolean;
}

const BaseLayout: FC<BaseProps> = (props) => {
  const { children, title, isLostConnectionError, unprocessedError, isErrorNotificationShown } = props;
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const { breadcrumbs } = useBreadcrumbsContext();
  const { resetCurrentUser, isCurrentUserLoading } = useUser();

  const router = useRouter();

  const handleMenuToggle = useCallback((event) => {
    setMenuAnchor(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const handleLogOut = () => AuthHelper.logout(resetCurrentUser, router);

  return (
    <>
      <HeaderBar
        handleMenuToggle={handleMenuToggle}
        menuAnchor={menuAnchor}
        handleMenuClose={handleMenuClose}
        handleLogOut={handleLogOut}
        isLostConnection={isLostConnectionError}
        unprocessedError={unprocessedError}
        isErrorNotificationShown={isErrorNotificationShown}
      />
      {isCurrentUserLoading ? (
        <Backdrop sx={styles.backdrop} open invisible>
          <CircularProgress />
        </Backdrop>
      ) : (
        <Box sx={styles.container}>
          {title && (
            <Box sx={styles.title}>
              {title}
            </Box>
          )}
          <Box sx={styles.breadcrumbs}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </Box>
          {children}
        </Box>
      )}
    </>
  );
};

export default BaseLayout;
