const CssBaselineOverrides: OverrideWithTheme = (theme) => ({
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        boxSizing: 'border-box',
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        backgroundColor: theme.palette.background.default,
      },
      '*, *::before, *::after': {
        boxSizing: 'inherit',
      },
      body: {
        fontSize: theme.typography.fontSize,
        padding: 0,
        margin: 0,
        backgroundColor: theme.palette.background.default,
        '@media print': {
          backgroundColor: theme.palette.white,
        },
      },
      a: {
        color: 'inherit',
        textDecoration: 'none',
        wordWrap: 'break-word',
        overflowWrap: 'anywhere',
        hyphens: 'auto',
      },
      'input, textarea, button': {
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
      },
      td: {
        wordWrap: 'break-word',
        overflowWrap: 'anywhere',
        hyphens: 'auto',
      },
    },
  },
});
  
export default CssBaselineOverrides;
  