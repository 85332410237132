const CheckboxOverrides: OverrideWithTheme = (theme) => ({
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: theme.palette.catskillWhite,
      },
    },
  },
});

export default CheckboxOverrides;
