import { ProviderContext, useSnackbar } from 'notistack';

import { callNotificationButton } from 'components/common/DismissNotificationButton/DismissNotificationButton';

type NotificationType = 'success' | 'error';
type SnackBar = ProviderContext['enqueueSnackbar'];

const useNotification = (notificationType: NotificationType = 'error', callbackOnClose = null): SnackBar => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleCloseSnackbar = (key) => () => {
    closeSnackbar(key);
    if (callbackOnClose) {
      callbackOnClose();
    }
  };

  const action = (key) => callNotificationButton(key, handleCloseSnackbar);

  return (message, options = {}) =>
    enqueueSnackbar(message, {
      variant: notificationType,
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      autoHideDuration: notificationType === 'error' ? null : 5000,
      action: notificationType === 'error' ? action : null,
      ...options,
    });
};

export const useErrorNotification = (callbackOnClose = null): SnackBar => useNotification('error', callbackOnClose);
export const useSuccessNotification = (callbackOnClose = null): SnackBar => useNotification('success', callbackOnClose);
