import { useState } from 'react';

type ModalWindowState = {
  isModalWindowOpen: boolean;
  handleModalWindowOpen: () => void;
  handleModalWindowClose: () => void;
};

export const useModalWindow = (isOpenInitially = false): ModalWindowState => {
  const [isOpen, setOpen] = useState<boolean>(isOpenInitially);

  const handleModalWindowOpen = () => {
    setOpen(true);
  };

  const handleModalWindowClose = () => {
    setOpen(false);
  };

  return { isModalWindowOpen: isOpen, handleModalWindowOpen, handleModalWindowClose };
};
