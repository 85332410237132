import { ResourceSlice, SliceOptions } from 'types/slices';

import { RequestState } from 'enums/requestState';

const resetResourceListSlice = (options: SliceOptions): ResourceSlice => {
  const { pluralResource } = options;

  return {
    initialState: {
      [pluralResource]: {
        updatingStatus: RequestState.isIdle,
        errors: null,
      },
    },
    reducers: {
      resetResourceList(state) {
        state[pluralResource].loadingStatus = RequestState.isIdle;
        state[pluralResource].items = [];
        state[pluralResource].error = null;
      },
    },
    actionCreators(restDispatch) {
      return {
        resetResourceList: () => {
          restDispatch('resetResourceList');
        },
      };
    },
    abstractSelector: (state) => ({
      resourceList: state[pluralResource],
    }),
  };
};

export default resetResourceListSlice;
