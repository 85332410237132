import { PaletteOptions } from '@mui/material/styles';

const COLORS = {
  white: '#fff',
  black: '#000',
  darkGray: '#383B3D',
  elephant: '#13334C',
  cornflowerBlue: '#DFE1E6',
  blackSqueeze: '#EDF2F9',
  santasGray: '#9699AE',
  athensGray: '#FAFBFC',
  catskillWhite: '#EAEFF5',
  ghost: '#cdced3',
  cerulean: '#00A3E0',
  pomegranate: '#F44336',
  linkWater: '#FBFCFE',
  whiteLilac: '#f6f8fc',
  foam: '#e6f6fc',
  taupe: '#3F586F',
  gray: '#8799A8',
  default: 'rgba(19, 51, 76, 0.05)',
};

const palette: PaletteOptions = {
  primary: {
    main: COLORS.cerulean,
  },
  secondary: {
    main: COLORS.default,
  },
  error: {
    main: COLORS.pomegranate,
  },
  text: {
    primary: COLORS.elephant,
  },
  background: {
    default: COLORS.linkWater,
    paper: COLORS.white,
  },
  ...COLORS,
};

export default palette;
