import { SxStyles } from 'theme';

const styles: SxStyles = {
  button: {
    background: 'transparent',
    color: 'white',
  },
};

export default styles;
