const InputBaseOverrides: OverrideWithTheme = (theme) => ({
  MuiInputBase: {
    styleOverrides: {
      root: {
        background: theme.palette.athensGray,

        '&.MuiOutlinedInput-root:hover fieldset': {
          borderColor: theme.palette.santasGray,
        },

        '&.MuiOutlinedInput-root.Mui-disabled': {
          '& fieldset': {
            background: theme.palette.blackSqueeze,
            borderColor: theme.palette.blackSqueeze,
            zIndex: 1,
          },
          '& input': {
            zIndex: 2,
            background: 'transparent',
          },
          '&:hover fieldset': {
            borderColor: theme.palette.blackSqueeze,
          },
        },
        '&.MuiOutlinedInput-root>textarea': {
          paddingTop: 10,
          paddingBottom: 10,
        },
        '&.Mui-focused': {
          background: theme.palette.white,
          borderRadius: 4,
          borderColor: theme.palette.primary.main,
        },
      },
      input: {
        height: 40,
        width: '100%',
        fontSize: theme.typography.pxToRem(14),
      },
      inputMarginDense: {
        height: 24,
        boxSizing: 'border-box',
      },
      inputSizeSmall: {
        height: 24,
      },
    },
  },
});

export default InputBaseOverrides;
