import { CacheTag } from 'enums/cacheTag';

import { selectDataFromResponse } from 'utils/responseData';

import { apiRoutes } from 'routes';
import { apiContainer } from 'store';

import { IOptions } from './types';

export const optionsApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    getOptions: builder.query<IOptions, void>({
      query: () => (
        {
          url: apiRoutes.optionsPath(),
          method: 'GET',
        }
      ),
      providesTags: [CacheTag.options],
      transformResponse: selectDataFromResponse,
    }),
  }),
  overrideExisting: false,
});

export const { useGetOptionsQuery, useLazyGetOptionsQuery } = optionsApi;
