import { SxStyles } from 'theme';

const styles: SxStyles = {
  content: {
    minWidth: 450,
    padding: 0,
    paddingLeft: theme => theme.spacing(1),
    paddingTop: theme => theme.spacing(2),
  },
  title: {
    paddingLeft: theme => theme.spacing(4),
    paddingRight: theme => theme.spacing(2),
    paddingTop: theme => theme.spacing(1),
    paddingBottom: theme => theme.spacing(1),
  },
  wrapper: {
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 3,
  },
};

export default styles;
