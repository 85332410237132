import { dateValidationRule } from 'utils/yup';

import * as yup from 'yup';

const LOAN_TERM_MAX_VALUE = 1200;
const PAYMENT_DAY_MIN_VALUE = 1;
export const PAYMENT_DAY_MAX_VALUE = 28;

const transformNumberToNull = (number) => number || null;

export const loanTermValidationRule = yup
  .number()
  .transform(transformNumberToNull)
  .max(LOAN_TERM_MAX_VALUE, `should be less than ${LOAN_TERM_MAX_VALUE}`)
  .nullable();

export const paymentDayValidationRule = yup
  .number()
  .transform(transformNumberToNull)
  .min(PAYMENT_DAY_MIN_VALUE, `should be more than ${PAYMENT_DAY_MIN_VALUE}`)
  .max(PAYMENT_DAY_MAX_VALUE, `should be less than ${PAYMENT_DAY_MAX_VALUE}`)
  .nullable();

export const maturityDateValidationRule = dateValidationRule
  .min(yup.ref('estimatedCloseDate'), 'Maturity date must later than the estimate close date')
  .nullable();
