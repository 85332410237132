import { SxStyles } from 'theme';

const styles: SxStyles = {
  anchor: {
    color: 'inherit',
    textDecoration: 'none',
    wordWrap: 'break-word',
    overflowWrap: 'anywhere',
    hyphens: 'auto',
  },
};

export default styles;
