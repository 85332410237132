import { useEffect, useRef, useState } from 'react';

import { SalesForceSyncUpStatus } from 'enums/salesForceSyncUpStatus';

import { CircularProgress, MenuItem } from '@mui/material';
import { useLazyGetStatusQuery, useStartSyncUpMutation } from 'domain/salesForceSyncUp/apiSlice';
import { useTranslation } from 'next-i18next';
import { propOr } from 'ramda';

import Box from 'components/common/Box';

import styles from './styles';

const SYNC_UP_TIME = 15000;

const SyncUpMenuItem: FC = () => {
  const [isSyncUpInProcess, setSyncUpInProcess] = useState<boolean>(true);
  const [, setSyncUpError] = useState<unknown>(null);
  const syncUpTimer = useRef(null);

  const { t } = useTranslation('common');

  const [ getStatus ] = useLazyGetStatusQuery();
  const [ startSyncUp ] = useStartSyncUpMutation();

  const handleStartSyncUp = async () => {
    try {
      const { data } = await getStatus({});
      const status = propOr(null, 'status')(data);
      setSyncUpInProcess(true);
      if (status === SalesForceSyncUpStatus.idle) {
        await startSyncUp({});
      }
    } finally {
      await getStatus({});
    }
  };

  const getSyncUpStatus = async () => {
    try {
      const response = await getStatus({});
      const data = propOr(null, 'data')(response);
      if (!data) return;
      const status = propOr(null, 'status')(data);
      if (status === SalesForceSyncUpStatus.started) {
        setSyncUpInProcess(true);
      }
      if (status === SalesForceSyncUpStatus.idle) {
        setSyncUpInProcess(false);
      }
    } catch (e) {
      setSyncUpError(e.response);
    }
  };

  useEffect(() => {
    getSyncUpStatus();
    clearInterval(syncUpTimer.current);
    syncUpTimer.current = setInterval(getSyncUpStatus, SYNC_UP_TIME);

    return () => {
      clearInterval(syncUpTimer.current);
    };
  }, []);

  return (
    <MenuItem sx={styles.userMenuItem} onClick={handleStartSyncUp} disabled={isSyncUpInProcess} data-test-id='menuItem'>
      {t('syncWithSalesForce')}
      {isSyncUpInProcess && (
        <Box sx={styles.loadingProgressBox}>
          <CircularProgress size={16} />
        </Box>
      )}
    </MenuItem>
  );
};

export default SyncUpMenuItem;
