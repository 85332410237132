import { Dispatch, SetStateAction, useState } from 'react';

import { Breadcrumbs } from 'types/components/breadcrumbs';

type BreadcrumbsState = {
  breadcrumbs: Breadcrumbs;
  resetBreadcrumbs: () => void;
  setBreadcrumbs: Dispatch<SetStateAction<Breadcrumbs>>;
};

export const useBreadcrumbs = (initialState: Breadcrumbs = []): BreadcrumbsState => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumbs>(initialState);

  const resetBreadcrumbs = () => {
    setBreadcrumbs([]);
  };

  return { breadcrumbs, setBreadcrumbs, resetBreadcrumbs };
};
