import rollbar from 'rollbar';

import { UNSAVED_CHANGES_ERROR_TEXT } from 'hooks/useUnsavedChangesWarning';

export const setUpRollbar = (): void => {
  rollbar.init({
    accessToken: process.env.CLIENT_ROLLBAR_ACCESS_TOKEN,
    enabled: ['qa', 'prod'].includes(process.env.CLIENT_ENV),
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.CLIENT_ENV,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: process.env.CLIENT_VERSION,
          guess_uncaught_frames: true,
        },
      },
    },
    ignoredMessages: [
      'Request failed with status code 401',
      'Request failed with status code 403',
      'Request failed with status code 404',
      'Script error.',
      UNSAVED_CHANGES_ERROR_TEXT,
    ],
  });
};
