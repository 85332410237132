export enum OpportunitySortingField {
  name = 'name',
  loanNumber = 'loanNumber',
  stage = 'stage',
  type = 'type',
  estimatedCloseDate = 'estimatedCloseDate',
  probability = 'probability',
  dealManagerFullName = 'dealManagerFullName',
  servicerName = 'servicerName',
  createdAt = 'createdAt',
}

export enum OpportunityTaskStatus {
  success = 'success',
  failure = 'failure',
  started = 'started',
}

export enum SecuritiesCostType {
  treasuries = 'treasuries',
}
