import { isBlank } from 'utils/predicates';

import { FormikErrors } from 'formik';
import { isNil } from 'ramda';
import * as yup from 'yup';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const lodashSet = require('lodash.set');

type Option<T> = {
  value: T;
  label: string;
};

type YupError = {
  path: string;
  errors: Array<string>;
};

type YupErrors = {
  inner: Array<YupError>;
};

export const optionSchema: yup.SchemaOf<Option<string | number>> = yup.object().shape({
  value: yup.string(),
  label: yup.string(),
});

const PHONE_NUMBER_LENGTH = 10;

export const dateValidationRule = yup.date().typeError('Invalid date');

export const phoneNumberRule: yup.AnySchema = yup
  .mixed()
  .test('filled-phone-number', 'Phone number is not valid', (value) => {
    const phoneDigits = value ? value.match(/\d/g) : null;

    if (isBlank(value) || isNil(phoneDigits)) {
      return true;
    }

    return phoneDigits ? phoneDigits.length === PHONE_NUMBER_LENGTH : false;
  });

export const getObjectFromFormikErrors = <T>(errors: FormikErrors<T>): FormikErrors<T> =>
  Object.entries(errors).reduce((accumulator, error) => {
    const [path, text] = error;
    lodashSet(accumulator, path, text);
    return accumulator;
  }, {});

export const yupErrorsToFormikErrors = <T>(yupErrors: YupErrors): FormikErrors<T> => {
  const errors = yupErrors.inner.reduce(
    (accumulator, error) => ({
      ...accumulator,
      [error.path]: error.errors.join(', '),
    }),
    {},
  );
  return getObjectFromFormikErrors(errors);
};
