/* eslint prefer-arrow-callback: [ "error", { "allowNamedFunctions": true } ] */
import * as React from 'react';

import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

import styles from './styles';

const Anchor = styled('a')({});

type NextLinkComposedProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
  to: NextLinkProps['href']
};

export const NextLinkComposed = React.forwardRef<HTMLAnchorElement, NextLinkComposedProps>(function NextLinkComposed(
  props,
  reference,
) {
  const { to, target, ...other } = props;

  return (
    <NextLink href={to} target={target} passHref>
      <Anchor ref={reference} {...other} />
    </NextLink>
  );
});

export type LinkProps = {
  href: NextLinkProps['href'];
  target?: string;
} & Omit<NextLinkComposedProps, 'to'> & MuiLinkProps;

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(function Link(props, reference) {
  const {
    href,
    target,
    ...other
  } = props;

  const nextjsProps = { to: href, target };

  return <MuiLink component={NextLinkComposed} ref={reference} {...nextjsProps} {...other} sx={styles.anchor}/>;
});

export default Link;
