export enum CacheTag {
    currentUser = 'currentUser',
    users = 'users',
    options = 'options',
    token = 'token',
    credIQ = 'credIQ',
    yardi ='yardi',
    successorBorrowers = 'successorBorrowers',
    multipacks = 'multipacks',
    accounts = 'accounts',
    contacts = 'contacts',
    pools = 'pools',
    opportunities = 'opportunities',
    managers = 'managers',
    servicers = 'servicers',
    opportunityContact = 'opportunityContact',
    pricingDetail = 'pricingDetail',
    document = 'document',
    credIQFinancial = 'credIQFinancial',
    lifeOfLoan = 'lifeOfLoan',
    moodysYardiLoanLookup = 'moodysYardiLoanLookup',
    amortizationSchedule = 'amortizationSchedule',
    transactionCost = 'transactionCost',
    securitiesCost = 'securitiesCost',
    successorBorrowerCompanies = 'successorBorrowerCompanies',
    loanLookup = 'loanLookup',
    sharepoint = 'sharepoint',
    yardiExport = 'yardiExport',
    salesForceSyncUp = 'salesForceSyncUp',
    email = 'email',
    opbDates = 'opbDates'
};
  