import React from 'react';

import { Breadcrumbs as BreadcrumbsType } from 'types/components/breadcrumbs';

import { Breadcrumbs as MuiBreadcrumbs, styled } from '@mui/material';
import Link from 'next/link';
import { isEmpty } from 'ramda';

import Box from 'components/common/Box';

import styles from './styles';

type BreadcrumbsProps = {
  breadcrumbs: BreadcrumbsType;
};

const A = styled('a')({});

const Breadcrumbs: FC<BreadcrumbsProps> = (props) => {
  const { breadcrumbs } = props;
  if (isEmpty(breadcrumbs)) {
    return null;
  }

  return (
    <MuiBreadcrumbs>
      {breadcrumbs.map((breadcrumb) => (
        <Link key={breadcrumb.link} href={breadcrumb.link}>
          <A sx={styles.breadcrumb} href={breadcrumb.link}>
            {breadcrumb.prefix && <Box sx={styles.prefix}><span>{breadcrumb.prefix}</span></Box>}
            {breadcrumb.name}
          </A>
        </Link>
      ))}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
