import { SliceOptions, ResourceSlice } from 'types/slices';

import { RequestState } from 'enums/requestState';

const loadResourceSlice = (options: SliceOptions): ResourceSlice => {
  const { resource, repository } = options;

  return {
    initialState: {
      [resource]: {
        loadingStatus: RequestState.isIdle,
        item: null,
      },
    },

    reducers: {
      loadResourceStart(state) {
        state[resource].loadingStatus = RequestState.isPending;
        state[resource].item = null;
      },
      loadResourceSuccess(state, { payload }) {
        state[resource].loadingStatus = RequestState.isSuccess;
        state[resource].item = payload.data;
      },
      loadResourceFail(state, { payload: { errors } }) {
        state[resource].loadingStatus = RequestState.isFailure;
        state[resource].errors = errors;
      },
    },

    actionCreators(restDispatch) {
      return {
        loadResource: async (id, params) => {
          try {
            restDispatch('loadResourceStart');
            const data = await repository.show(id, params);
            restDispatch('loadResourceSuccess', data);
          } catch (errors) {
            restDispatch('loadResourceFail', { errors });
          }
        },
      };
    },
  };
};

export default loadResourceSlice;
