import { opportunityRepository } from 'repositories/opportunityRepository';

import { IDataListServerResponse } from 'types/api';
import { PromiseFunctionRecord } from 'types/slices';

import { createRestSlice } from 'utils/RestSlice';

import { createSlice } from '@reduxjs/toolkit';
import { IOpportunityIndex } from 'domain/opportunity/types';
import { useDispatch } from 'react-redux';

const opportunityRestSlice = createRestSlice({
  resource: 'opportunity',
  repository: opportunityRepository,
  slices: [
    'loadOpportunity',
    'loadOpportunityList',
    'createOpportunity',
    'deleteOpportunity',
    'updateOpportunity',
    'resetOpportunity',
    'resetOpportunityList',
  ],
});

const yieldMaintenanceState = {
  yieldMaintenance: null,
  isYieldMaintenanceLoading: false,
  yieldMaintenanceLoadError: null,
};

const opportunityCloneState = {
  opportunityCloneId: null,
  opportunityCloneLoading: false,
  opportunityCloneLoadError: null,
};

const loanLookupListState = {
  loanLookupList: [],
  isLoanLookupListLoading: false,
  loanLookupListLoadError: null,
};

const opportunityUpdateListState = {
  opportunityUpdateList: [],
  isOpportunityUpdateListLoading: false,
  isOpportunityUpdateListLoadError: null,
};

const opportunityForPricingDetailsState = {
  opportunityForPricingDetails: [],
  isOpportunityForPricingDetailsLoading: false,
  opportunityForPricingDetailsLoadError: null,
};

const opportunityDuplicatesState = {
  opportunityDuplicates: [],
  areOpportunityDuplicatesLoading: false,
  opportunityDuplicatesLoadError: null,
};

const initialState = {
  ...opportunityRestSlice.initialState,
  ...yieldMaintenanceState,
  ...loanLookupListState,
  ...opportunityDuplicatesState,
  ...opportunityCloneState,
  ...opportunityUpdateListState,
  ...opportunityForPricingDetailsState,
};

const yieldMaintenanceReducers = {
  startYieldMaintenanceLoad(state) {
    state.isYieldMaintenanceLoading = true;
    state.yieldMaintenance = null;
    state.yieldMaintenanceLoadError = null;
  },
  yieldMaintenanceLoadFulfilled(state, { payload }) {
    state.isYieldMaintenanceLoading = false;
    state.yieldMaintenance = payload;
  },
  yieldMaintenanceLoadRejected(state, { payload }) {
    state.isYieldMaintenanceLoading = false;
    state.yieldMaintenance = null;
    state.yieldMaintenanceLoadError = payload;
  },
};

const opportunityCloneReducers = {
  startOpportunityCloneLoad(state) {
    state.isOpportunityCloneLoading = true;
    state.opportunityCloneId = null;
    state.opportunityCloneLoadError = null;
  },
  opportunityCloneSuccess(state, { payload }) {
    state.isOpportunityCloneLoading = false;
    state.opportunityCloneId = payload?.data?.id || null;
  },
  opportunityCloneFail(state, { payload }) {
    state.isOpportunityCloneLoading = false;
    state.opportunityCloneId = null;
    state.opportunityCloneLoadError = payload;
  },
};

const loanLookupListReducers = {
  startLoanLookupListLoad(state) {
    state.isLoanLookupListLoading = true;
    state.loanLookupList = [];
    state.loanLookupListLoadError = null;
  },
  loanLookupListLoadFulfilled(state, { payload }) {
    state.isLoanLookupListLoading = false;
    state.loanLookupList = payload;
  },
  loanLookupSetMoodysDate(state, { payload }) {
    state.pulledFromMoodysDate = payload;
  },
  loanLookupListLoadRejected(state, { payload }) {
    state.isLoanLookupListLoading = false;
    state.loanLookupList = [];
    state.loanLookupListLoadError = payload;
  },
};

const opportunityDuplicatesReducers = {
  startOpportunityDuplicatesLoad(state) {
    state.areOpportunityDuplicatesLoading = true;
    state.opportunityDuplicates = [];
    state.opportunityDuplicatesLoadError = null;
  },
  opportunityDuplicatesLoadFulfilled(state, { payload }) {
    state.areOpportunityDuplicatesLoading = false;
    state.opportunityDuplicates = payload;
  },
  opportunityDuplicatesLoadRejected(state, { payload }) {
    state.areOpportunityDuplicatesLoading = false;
    state.opportunityDuplicates = [];
    state.opportunityDuplicatesLoadError = payload;
  },
};

const opportunityUpdateListReducers = {
  startOpportunityUpdatedListLoad(state) {
    state.isOpportunityUpdateListLoading = true;
    state.opportunityUpdateList = [];
    state.opportunityUpdateListLoadError = null;
  },
  opportunityUpdatedListFulfilled(state, { payload }) {
    state.isOpportunityUpdateListLoading = false;
    state.opportunityUpdateList = payload;
  },
  opportunityUpdatedListRejected(state, { payload }) {
    state.isOpportunityUpdateListLoading = false;
    state.opportunityUpdateList = [];
    state.opportunityUpdateListLoadError = payload;
  },
};

const opportunityForPricingDetailsReducers = {
  startOpportunityForPricingDetailsLoad(state) {
    state.isOpportunityForPricingDetailsLoading = true;
    state.opportunityForPricingDetails = [];
    state.opportunityForPricingDetailsLoadError = null;
  },
  opportunityForPricingDetailsLoadFulfilled(state, { payload }) {
    state.isOpportunityForPricingDetailsLoading = false;
    state.opportunityForPricingDetails = payload;
  },
  opportunityForPricingDetailsLoadRejected(state, { payload }) {
    state.isOpportunityForPricingDetailsLoading = false;
    state.opportunityForPricingDetails = [];
    state.opportunityForPricingDetailsLoadError = payload;
  },
};

const opportunityListChangeReducers = {
  opportunityChangeList(state, { payload }) {
    state.opportunityList.items = payload;
  },
};

const opportunitySlice = createSlice({
  name: 'opportunity',
  initialState,
  reducers: {
    ...opportunityRestSlice.reducers,
    ...yieldMaintenanceReducers,
    ...loanLookupListReducers,
    ...opportunityDuplicatesReducers,
    ...opportunityCloneReducers,
    ...opportunityUpdateListReducers,
    ...opportunityListChangeReducers,
    ...opportunityForPricingDetailsReducers,
  },
});

export const useYieldMaintenanceActions = (): PromiseFunctionRecord<void> => {
  const dispatch = useDispatch();

  const loadYieldMaintenance = async (opportunityId: number) => {
    try {
      dispatch(opportunitySlice.actions.startYieldMaintenanceLoad());
      const { data } = await opportunityRepository.yieldMaintenanceLoad(opportunityId);
      dispatch(opportunitySlice.actions.yieldMaintenanceLoadFulfilled(data));
    } catch (e) {
      dispatch(opportunitySlice.actions.yieldMaintenanceLoadRejected(e));
    }
  };

  const resetYieldMaintenance = () => {
    dispatch(opportunitySlice.actions.yieldMaintenanceLoadFulfilled(null));
  };

  return {
    loadYieldMaintenance,
    resetYieldMaintenance,
  };
};

export const useLoanLookupListActions = (): PromiseFunctionRecord<void> => {
  const dispatch = useDispatch();

  const loadLoanLookupList = async (params: QueryParams) => {
    try {
      dispatch(opportunitySlice.actions.startLoanLookupListLoad());
      const { results } = await opportunityRepository.loanLookupListLoad(params);
      dispatch(opportunitySlice.actions.loanLookupListLoadFulfilled(results));
    } catch (e) {
      dispatch(opportunitySlice.actions.loanLookupListLoadRejected(e));
    }
  };

  const setMoodysDate = () => {
    dispatch(opportunitySlice.actions.loanLookupSetMoodysDate(new Date()));
  };

  const clearMoodysDate = () => {
    dispatch(opportunitySlice.actions.loanLookupSetMoodysDate(null));
  };

  return {
    loadLoanLookupList,
    setMoodysDate,
    clearMoodysDate,
  };
};

export const useOpportunityDuplicatesActions = (): PromiseFunctionRecord<IDataListServerResponse<IOpportunityIndex>> => {
  const dispatch = useDispatch();

  const loadOpportunityDuplicates = async (params: QueryParams) => {
    try {
      dispatch(opportunitySlice.actions.startOpportunityDuplicatesLoad());
      const data = await opportunityRepository.index(params);
      dispatch(opportunitySlice.actions.opportunityDuplicatesLoadFulfilled(data));
      return data;
    } catch (e) {
      dispatch(opportunitySlice.actions.opportunityDuplicatesLoadRejected(e));
      return e;
    }
  };

  return {
    loadOpportunityDuplicates,
  };
};

export const useOpportunityListAdditionalActions = (): PromiseFunctionRecord<void> => {
  const dispatch = useDispatch();

  const updateOpportunityList = async (estimatedCloseDate: string, params: QueryParams) => {
    try {
      dispatch(opportunitySlice.actions.startOpportunityUpdatedListLoad());
      const data = await opportunityRepository.multipleUpdate(estimatedCloseDate, params);
      dispatch(opportunitySlice.actions.opportunityUpdatedListFulfilled(data));
    } catch (e) {
      dispatch(opportunitySlice.actions.opportunityUpdatedListRejected(e));
    }
  };

  const changeOpportunityList = async (data) => {
    dispatch(opportunitySlice.actions.opportunityChangeList(data));
  };

  return {
    updateOpportunityList,
    changeOpportunityList,
  };
};

export const useOpportunityCloneAction = (): PromiseFunctionRecord<void> => {
  const dispatch = useDispatch();

  const cloneOpportunity = async (id: number) => {
    try {
      dispatch(opportunitySlice.actions.startOpportunityCloneLoad());
      const data = await opportunityRepository.clone(id);
      dispatch(opportunitySlice.actions.opportunityCloneSuccess(data));
    } catch (e) {
      dispatch(opportunitySlice.actions.opportunityCloneFail(e));
    }
  };

  const resetOpportunityCloneId = () => {
    dispatch(opportunitySlice.actions.opportunityCloneSuccess(null));
  };

  return {
    cloneOpportunity,
    resetOpportunityCloneId,
  };
};

export default opportunitySlice.reducer;
export const opportunityRestHooks = opportunityRestSlice.hooks(opportunitySlice, 'OpportunitySlice');
