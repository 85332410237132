import caseHelpers from 'case';
import humps from 'humps';
import { keys } from 'ramda';

const isFile = (obj) => obj instanceof File;
const isFormData = (obj) => obj instanceof FormData;

export const camelize = <T>(obj: T): T =>
  humps.camelizeKeys(obj, (key, convert) => (caseHelpers.of(key) === 'constant' ? key : convert(key)));

export const decamelize = <T>(obj: T): T => (!isFile(obj) && !isFormData(obj) ? humps.decamelizeKeys(obj) : obj);

export const decamelizeString = (value: string): string => humps.decamelize(value);

export const getKeyByValue = (obj: Record<string, unknown>, value: string[]) =>
  keys(obj).find((key) => obj[key] === value);
