export enum FieldType {
  text = 'text',
  select = 'select',
  checkBox = 'checkBox',
  checkBoxGroup = 'checkBoxGroup',
  multipleSelect = 'multipleSelect',
  phone = 'phone',
  money = 'money',
  integer = 'integer',
  float = 'float',
  date = 'date',
  autocomplete = 'autocomplete',
  percentage = 'percentage',
  editablePercentage = 'editablePercentage',
}
