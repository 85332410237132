import { CacheTag } from 'enums/cacheTag';

import { selectDataFromResponse } from 'utils/responseData';

import { apiRoutes } from 'routes';
import { apiContainer } from 'store';

import { ISyncUpResponse } from './types';

export const salesForceSyncUpApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    startSyncUp: builder.mutation<ISyncUpResponse, Record<string, unknown>>({
      query: () => ({
        url: apiRoutes.salesForceSyncPath(),
        method: 'POST',
      }),
      invalidatesTags: [{ type: CacheTag.salesForceSyncUp, id: 'LIST' }],
      transformResponse: selectDataFromResponse,
    }),
    getStatus: builder.query<ISyncUpResponse, Record<string, unknown>>({
      query: () => ({
        url: apiRoutes.salesForceSyncPath(),
        method: 'GET',
      }),
      providesTags: [CacheTag.salesForceSyncUp],
      transformResponse: selectDataFromResponse,
    }),
  }),
  overrideExisting: false,
});

export const {
  useStartSyncUpMutation,
  useLazyGetStatusQuery,
} = salesForceSyncUpApi;
