import { ComponentsProps } from '@mui/material/styles';

const TextFieldProps: ComponentsProps = {
  MuiTextField: {
    InputLabelProps: {
      shrink: false,
    },
  },
};

export default TextFieldProps;
