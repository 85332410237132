import { createContext, FC, useState, useMemo } from 'react';

import { ConfirmationVariant } from 'enums/components/confirmation/variants';

import Confirmation from 'components/common/Confirmation';

import { IConfirmConfig, IConfirmProviderProps } from './types';

export const ConfirmContext = createContext(null);

const ConfirmProvider: FC<IConfirmProviderProps> = props => {
  const { children } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<IConfirmConfig>({} as IConfirmConfig);
  const [confirmationTitle, setConfirmationTitle] = useState<string>('');
  const [confirmationText, setConfirmationText] = useState<string>('');

  const openDialog = ({ actionCallback }: IConfirmConfig, title, text) => {
    setDialogOpen(true);
    setDialogConfig({ actionCallback });
    setConfirmationTitle(title);
    setConfirmationText(text);
  };

  const confirmValue = useMemo(() => ({ openDialog }), []);

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig({} as IConfirmConfig);
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig.actionCallback(true);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig.actionCallback(false);
  };

  return (
    <ConfirmContext.Provider value={confirmValue}>
      {children}
      <Confirmation 
        isOpen={dialogOpen} 
        onConfirm={onConfirm}  
        onClose={onDismiss} 
        title={confirmationTitle} 
        variant={ConfirmationVariant.danger} 
        text={confirmationText}
      />
    </ConfirmContext.Provider>
  );
};

export default ConfirmProvider;
