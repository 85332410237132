const InputLabelOverrides: OverrideWithTheme = (theme) => ({
  MuiSelect: {
    styleOverrides: {
      root: {
        minHeight: 40,
      },
      selectMenu: {
        height: 40,
        lineHeight: theme.typography.pxToRem(40),
        alignItems: 'center',
      },
      iconOutlined: {
        right: 9,
      },
    },
  },
});

export default InputLabelOverrides;
