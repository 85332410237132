const TextFieldOverrides: OverrideWithTheme = () => ({
  MuiTextField: {
    styleOverrides: {
      root: {
        marginTop: 20,
        marginBottom: 16,
      },
    },
  },
});

export default TextFieldOverrides;
