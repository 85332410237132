import palette from 'theme/palette';

const InputLabelOverrides: OverrideWithTheme = (theme) => ({
  MuiInputLabel: {
    styleOverrides: {
      shrink: {
        position: 'static',
        marginBottom: theme.spacing(0.75),
        color: palette.text.primary,
        opacity: 0.5,
        transform: 'translate(0, 0px) scale(0.90)',
      },
      outlined: {
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(12),
        color: palette.text.primary,
        opacity: 0.5,
        transform: 'translate(0, -20px)',
        '&.Mui-focused': {
          color: palette.text.primary,
        },
        '&.Mui-error': {
          color: palette.text.primary,
        },
      },
    },
  },
});

export default InputLabelOverrides;
