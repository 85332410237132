import { makeRoutes } from 'utils/routes';

const appRoutes = makeRoutes({
  rootPath: () => '/',
  loginPath: () => '/login',
  passwordPath: () => '/password',
  passwordForgotPath: () => '/password/forgot',
  passwordResetPath: () => '/password/reset',
  accountsPath: () => '/accounts',
  contactsPath: () => '/contacts',
  yardiServicePath: () => '/yardi-service',
  credIQPath: () => '/crediq',
  reportsPath: () => '/reports',
  accountPath: (id) => `/accounts/${id}`,
  contactPath: (contactId: number) => `/contacts/${contactId}`,
  accountSettingsPath: () => '/account-settings',
  accountSettingsChangePasswordPath: () => '/account-settings/change-password',
  opportunitiesPath: () => '/opportunities',
  opportunityPath: (opportunityId: number) => `/opportunities/${opportunityId}`,
  resourcesPath: () => '/resources',
  resourcesPoolsPath: () => '/resources/pools',
  resourcesPoolsNewPath: () => '/resources/pools/new',
  resourcesPoolPath: (poolId: number) => `/resources/pools/${poolId}`,
  resourcesMultipacksPath: () => '/resources/multipacks',
  resourcesMultipacksNewPath: () => '/resources/multipacks/new',
  resourcesMultipackPath: (multipackId: number) => `/resources/multipacks/${multipackId}`,
  resourcesSuccessorBorrowersPath: () => '/resources/successor_borrowers',
  resourcesSuccessorBorrowersNewPath: () => '/resources/successor_borrowers/new',
  resourcesSuccessorBorrowerPath: (successorBorrowerId: number) =>
    `/resources/successor_borrowers/${successorBorrowerId}`,
  pageNotFoundPath: () => '/404',
  pageServerErrorPath: () => '/500',
});

export default appRoutes;
