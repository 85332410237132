import { Button } from '@mui/material';
import { SnackbarKey } from 'notistack';

import styles from './styles';

type CloseSnackbarType = (key: SnackbarKey) => () => void;

type DismissNotificationButtonProps = {
  onClick: () => void;
};

const DismissNotificationButton: FC<DismissNotificationButtonProps> = (props) => {
  const { onClick } = props;
  return (
    <Button variant='text' sx={styles.button} onClick={onClick}>
      Dismiss
    </Button>
  );
};

export const callNotificationButton = (key: SnackbarKey, handleCloseSnackbar: CloseSnackbarType): JSX.Element => (
  <DismissNotificationButton onClick={handleCloseSnackbar(key)} />
);

export default DismissNotificationButton;
