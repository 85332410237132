import AutocompleteOverrides from './AutocompleteOverrides';
import BreadcrumbsOverrides from './BreadcrumbsOverrides';
import ButtonOverrides from './ButtonOverrides';
import CheckboxOverrides from './CheckboxOverrides';
import CssBaselineOverrides from './CssBaselineOverrides';
import FormControlLabelOverrides from './FormControlLabelOverrides';
import FormHelperTextOverrides from './FormHelperTextOverrides';
import InputBaseOverrides from './InputBaseOverrides';
import InputLabelOverrides from './InputLabelOverrides';
import OutlinedInputOverrides from './OutlinedInputOverrides';
import RadioOverrides from './RadioOverrides';
import SelectOverrides from './SelectOverrides';
import TableCellOverrides from './TableCellOverrides';
import TableOverrides from './TableOverrides';
import TextFieldOverrides from './TextFieldOverrides';
import TypographyOverrides from './TypographyOverrides';

const overrides: OverrideWithTheme = (theme) => ({
  ...AutocompleteOverrides(theme),
  ...TypographyOverrides(theme),
  ...BreadcrumbsOverrides(theme),
  ...ButtonOverrides(theme),
  ...InputBaseOverrides(theme),
  ...OutlinedInputOverrides(theme),
  ...InputLabelOverrides(theme),
  ...RadioOverrides(theme),
  ...SelectOverrides(theme),
  ...TableCellOverrides(theme),
  ...TableOverrides(theme),
  ...TextFieldOverrides(theme),
  ...FormHelperTextOverrides(theme),
  ...FormControlLabelOverrides(theme),
  ...CheckboxOverrides(theme),
  ...CssBaselineOverrides(theme),
});

export default overrides;
