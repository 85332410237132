import { getFileData } from 'helpers/FetchHelper';

import FileSaver from 'file-saver';
import { last } from 'ramda';

import { isBlank } from './predicates';

const DELAY_TIME = 5000;
const TIMER = 180000;
let totalTime = 0;

export const downloadFile = async (url: string): Promise<void> => {
  try {
    totalTime += DELAY_TIME;
    const response = await getFileData(url);
    if (!isBlank(response)) {
      const { name, content } = response;
      FileSaver.saveAs(content, name);
    };
  } catch (error) {
    if (totalTime <= TIMER) setTimeout(downloadFile, DELAY_TIME, url);
  }
};

export const getFileName = (url: string, contentDisposition: string): string => {
  if (contentDisposition) {
    return last(contentDisposition.match(/filename="(.+)"/));
  }
  const amazonS3File = url.match(/s3.amazonaws.com\/(.*?)\?/);
  return decodeURIComponent(last(last(amazonS3File).split('/')));
};
