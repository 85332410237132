import { SxStyles } from 'theme';

const styles: SxStyles = {
  appBar: {
    backgroundColor: 'text.primary',
    height: theme => theme.layouts.baseLayout.header.height,
  },
  container: {
    marginTop: 4,
    paddingRight: 4,
    paddingLeft: 4,
  },
  toolBar: {
    minHeight: 48,
  },
  title: {
    fontSize: theme => theme.typography.pxToRem(29),
    color: 'text.primary',
    marginBottom: 4,
  },
  logo: {
    position: 'absolute',
  },
  breadcrumbs: {
    position: 'relative',
    zIndex: 1,
    marginBottom: 1,
  },
  userMenuButton: {
    color: 'white',
    opacity: 0.7,
    whiteSpace: 'nowrap',
  },
  userMenuIcon: {
    width: 18,
    height: 18,
    marginLeft: theme => theme.spacing(0.5),
  },
  userMenu: {
    marginTop: theme => theme.spacing(5.5),
  },
  userMenuItem: {
    minHeight: 0,
    minWidth: 150,
  },
  backdrop: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 2,
    background: 'white',
  },
};

export default styles;
