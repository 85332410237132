import { ResourceSlice, SliceOptions } from 'types/slices';

import { RequestState } from 'enums/requestState';

const createResourceSlice = (options: SliceOptions): ResourceSlice => {
  const { resource, repository } = options;

  return {
    initialState: {
      [resource]: {
        creatingStatus: RequestState.isIdle,
        id: null,
        errors: null,
      },
    },
    reducers: {
      createResourceStart(state) {
        state[resource].creatingStatus = RequestState.isPending;
        state[resource].errors = null;
      },
      createResourceSuccess(state, { payload: { data } }) {
        state[resource].creatingStatus = RequestState.isSuccess;
        state[resource].id = data.id;
      },
      createResourceFail(state, { payload: { errors } }) {
        state[resource].creatingStatus = RequestState.isFailure;
        state[resource].errors = errors;
      },
    },
    actionCreators(restDispatch) {
      return {
        createResource: async (params) => {
          try {
            restDispatch('createResourceStart');
            const payload = await repository.create(params);
            restDispatch('createResourceSuccess', payload);
            return payload.data.id;
          } catch (errors) {
            restDispatch('createResourceFail', { errors });
            return errors;
          }
        },
      };
    },
    abstractSelector: (state) => ({
      resource: state[resource],
    }),
  };
};

export default createResourceSlice;
