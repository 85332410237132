const BreadcrumbsOverrides: OverrideWithTheme = (theme) => ({
  MuiBreadcrumbs: {
    styleOverrides: {
      li: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 500,
        opacity: 0.5,
        color: theme.palette.text.primary,
      },
      separator: {
        display: 'flex',
        alignItems: 'center',
        height: 18,
        position: 'relative',
        top: 1,
      },
    },
  },
});

export default BreadcrumbsOverrides;
