import { useCallback, useState } from 'react';

import { useRouter } from 'next/router';

import { useModalWindow } from 'hooks/useModalWindow';
import { useRouteChangePrevent } from 'hooks/useRouteChangePrevent';

export const UNSAVED_CHANGES_ERROR_TEXT = 'Unsaved changes found';

type UnsavedChangesWarningState = {
  nextPageURL: string | null;
  isLeaveWithoutSavingChangesConfirmationOpen: boolean;
  handleLeaveWithoutSavingChangesConfirmationClose: () => void;
  handleLeaveWithoutSavingChanges: () => void;
  setShouldWarnUser: (shouldWarnUser: boolean) => void;
};

export const useUnsavedChangesWarning = (): UnsavedChangesWarningState => {
  const [shouldWarnUser, setShouldWarnUser] = useState<boolean>(false);
  const [nextPageURL, setNextPageURL] = useState<string | null>(null);
  const router = useRouter();

  const {
    isModalWindowOpen: isLeaveWithoutSavingChangesConfirmationOpen,
    handleModalWindowClose: handleLeaveWithoutSavingChangesConfirmationClose,
    handleModalWindowOpen: handleLeaveWithoutSavingChangesConfirmationOpen,
  } = useModalWindow();

  const handleLeaveWithoutSavingChanges = useCallback(() => {
    setShouldWarnUser(false);
    handleLeaveWithoutSavingChangesConfirmationClose();
    router.push(nextPageURL);
  }, [nextPageURL]);

  const handleRouteChangePrevent = useCallback(
    (URL) => {
      if (nextPageURL !== URL) {
        handleLeaveWithoutSavingChangesConfirmationOpen();
        setNextPageURL(URL);
      }
    },
    [nextPageURL],
  );

  const shouldPreventRouteChange = shouldWarnUser && !isLeaveWithoutSavingChangesConfirmationOpen;

  useRouteChangePrevent(handleRouteChangePrevent, shouldPreventRouteChange, UNSAVED_CHANGES_ERROR_TEXT);

  return {
    nextPageURL,
    isLeaveWithoutSavingChangesConfirmationOpen,
    setShouldWarnUser,
    handleLeaveWithoutSavingChangesConfirmationClose,
    handleLeaveWithoutSavingChanges,
  };
};
