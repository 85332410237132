import { isBlank } from 'utils/predicates';

import { add, format, isValid, setDate, subDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { isNil } from 'ramda';

const getDateWithoutOffset = (date, timezone = 'UTC') => {
  const inputDate = utcToZonedTime(typeof date === 'string' ? new Date(date) : date, timezone);
  return inputDate;
};

export const DateHelpers = {
  toIso: (date: Date | string | null, timezone = 'UTC'): string => {
    const inputData = typeof date === 'string' ? utcToZonedTime(new Date(date), timezone) : date;

    return isValid(inputData) ? format(inputData, 'yyyy-MM-dd') : null;
  },
  toIsoWithoutUTC: (date: Date | string | null): string => {
    const inputData = typeof date === 'string' ? new Date(date) : date;

    return isValid(inputData) ? format(inputData, 'yyyy-MM-dd') : null;
  },
  toIsoWithTime: (date: Date | string | null, timezone = 'UTC'): string => {
    const inputData = typeof date === 'string' ? utcToZonedTime(new Date(date), timezone) : date;

    return isValid(inputData) ? format(inputData, 'yyyy-MM-dd hh:mm') : null;
  },
  toDateWithFirstDayOfMonth: (date: Date | string | null): Date | null => {
    if (typeof date === 'string') return setDate(new Date(date), 1);
    if (isNil(date)) return null;
    return setDate(date, 1);
  },
  toDateWithDayOfMonth: (date: Date | string | null, day: number): Date | null => {
    if (typeof date === 'string') return setDate(new Date(date), day);
    if (isNil(date)) return null;
    return setDate(date, day);
  },
  toReadable: (date: Date | null | string): string => {
    if (isBlank(date)) {
      return null;
    }
    const dateWithoutOffset = getDateWithoutOffset(date);
    return format(dateWithoutOffset, 'MM/dd/yyyy');
  },
  toDate: (date: string | null, timezone = 'UTC'): Date | null => {
    if (isNil(date)) {
      return null;
    }
    return utcToZonedTime(new Date(date), timezone);
  },
  dateAndTimeFormatted: (date: Date | string | null): string => {
    if (isBlank(date)) {
      return null;
    }
    const inputData = typeof date === 'string' ? new Date(date) : date;
    return format(inputData, 'MM/dd/yyyy hh:mm a');
  },
  addMonths: (date: Date | string | null, monthsAmount: number, removeOffset = true): string => {
    const newDate = add(new Date(date), { months: monthsAmount });

    const dateWithoutOffset = removeOffset ? getDateWithoutOffset(newDate) : newDate;
    return format(dateWithoutOffset, 'MM/dd/yyyy');
  },
  isValidDate: (date: Date | string | null): boolean => {
    if (typeof date === 'string') return isValid(new Date(date));
    if (isNil(date)) return false;
    return isValid(date);
  },
  getDatesDifferenceInSeconds: (firstDate: Date, secondDate: Date): number =>
    (firstDate.getTime() - secondDate.getTime()) / 1000,

  getYesterdayDate: (currentDate = new Date()): Date => subDays(currentDate, 1),
};
